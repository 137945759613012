import { useState, useContext } from "react";
import {
  Button,
  Modal,
  Form,
  DatePicker,
  Select,
  notification,
  Checkbox,
} from "antd";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../utils/utilities";
import "./ExportTasksModal.css";
import ConfigContext from "../context/ConfigContext";

const { RangePicker } = DatePicker;
const { Option } = Select;

const ExportTasksModal = () => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const config = useContext(ConfigContext);

  const handleExport = async (values) => {
    const { dateRange, status, legacy_export = false } = values;
    let requestParams = {};
    if (dateRange !== undefined) {
      const start_date = dateRange[0].startOf("day").toISOString();
      const end_date = dateRange[1].endOf("day").toISOString();
      requestParams = { start_date, end_date, status, legacy_export };
    } else {
      requestParams = { legacy_export };
    }

    AuthenticatedApi.post("/api/tasks/export", requestParams, {
      responseType: "blob",
      timeout: 120000, // 2 minutes
    })
      .then(async (response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = "tasks.csv";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();

        notification.success({
          message: formatMessage(config.messages.tasks.export_title),
          description: formatMessage(config.messages.tasks.export_success),
        });
        form.resetFields();
        setVisible(false);
      })
      .catch((error) => {
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
      });
  };

  return (
    <>
      <Button
        type="link"
        onClick={() => setVisible(true)}
        title="Export task to CSV."
      >
        Export Tasks
      </Button>
      <Modal
        title="Export Tasks"
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        onOk={() => form.submit()}
      >
        <Form form={form} layout="vertical" onFinish={handleExport}>
          <Form.Item
            name="dateRange"
            label="Date Range"
            rules={[
              { required: false, message: "Please select the date range!" },
            ]}
          >
            <RangePicker />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: false, message: "Please select a status!" }]}
          >
            <Select placeholder="Select a status">
              <Option value="Blocked">Blocked</Option>
              <Option value="Completed">Completed</Option>
              <Option value="Failed">Failed</Option>
            </Select>
          </Form.Item>
          <Form.Item name="legacy_export" valuePropName="checked">
            <Checkbox>Legacy Export</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Export
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ExportTasksModal;
