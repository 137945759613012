import { useContext } from "react";
import "react-mde/lib/styles/css/react-mde-all.css";
import "./MdeStyling.css";
import { Button, notification, Typography } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import "./CopyIconToClipboard.css"; // Import the CSS file with styling
import { formatMessage } from "../utils/utilities";
import { useTheme } from "../context/ThemeContext";
import ConfigContext from "../context/ConfigContext";

function ApiSecretDialog(props) {
  const config = useContext(ConfigContext);

  const { isDarkMode } = useTheme();
  const { Title } = Typography;
  // eslint-disable-next-line react/prop-types

  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    //el.value = details;
    el.value = props.secret;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    notification.success({ message: formatMessage(config.messages.copy.copy) });
  };

  const generatedLetterBackgroundStyle = {
    backgroundColor: isDarkMode ? "#1f1f1f" : "#e3e3e3",
  };

  return (
    <div className="generated-letter" style={generatedLetterBackgroundStyle}>
      <Title level={3}>{props.title}</Title>
      <div>
        This is the API Secret for accessing the Synthpop API. It will only be
        shown once and Synthpop does not store it. Make sure to store it in a
        safe place before closing this window.
      </div>
      <div className="button-container">
        <Button
          icon={<CopyOutlined />}
          onClick={copyToClipboard}
          title="Copy to clipbord"
        ></Button>
      </div>

      <pre style={{ marginTop: "10px" }}>{props.secret}</pre>
    </div>
  );
}

export default ApiSecretDialog;
