import React, { useState, useContext } from "react";
import { Form, Button, Typography, Upload, notification, Input } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../utils/utilities";
import ConfigContext from "../context/ConfigContext";

const { Title } = Typography;
const TaskReferralEntry = (props) => {
  const [fileList, setFileList] = useState(props.fileList);
  const [waiting] = useState(props.waiting);
  const [enabledSubmit, setEnabledSubmit] = useState(props.enabledSubmit);
  const [tasks] = useState(props.tasks);
  const setTasks = props.setTasks;
  const handleCancel = props.handleCancel;
  const setWaiting = props.setWaiting;
  const taskType = props.taskType;
  const taskName = props.taskName;
  const config = useContext(ConfigContext);

  const handleUpload = ({ fileList }) => {
    setFileList(fileList);
    setEnabledSubmit(fileList.length > 0);
  };

  const clear = () => {
    setFileList([]);
    setEnabledSubmit(false);
    setWaiting(false);
    handleCancel();
  };

  const toSnakeCase = (str) => {
    return str.toLowerCase().replace(/\s+/g, "_");
  };

  const csvStringToJson = (csvString) => {
    const keys = csvString.split(",").map((key) => toSnakeCase(key.trim()));
    const jsonObject = {};
    keys.forEach((key) => {
      jsonObject[key] = "";
    });
    return jsonObject;
  };
  const handleSubmit = async (values) => {
    setWaiting(true);
    let data_items = [
      {
        name: "Task Requirements",
        tag: "request-spec",
        data_type: "json",
        data: {},
      },
    ];

    let text_input = (values["custom_field_extraction"] || "").trim();
    if (text_input.length > 0) {
      const jsonObject = csvStringToJson(text_input);
      data_items.push({
        tag: "custom-field-extraction",
        data_type: "json",
        data: jsonObject,
      });
    } else {
      data_items.push({
        tag: "custom-field-extraction",
        data_type: "json",
        data: {},
      });
    }

    fileList.forEach((file, index) => {
      data_items.push({
        tag: "upload",
        data_type: "file",
        data: index,
        file_name: file.originFileObj.name,
      });
    });

    let createTask = {
      task_type: taskType || "health_record",
      data_items: data_items,
    };

    let formData = new FormData();
    formData.append("create_task", JSON.stringify(createTask));
    console.log(fileList);
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("uploads", file.originFileObj);
      });
    }
    console.log(formData);
    AuthenticatedApi.post("/api/v2/task/create", formData)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          setTasks([response.data, ...tasks]);
        }
        clear();
      })
      .catch((error) => {
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
        clear();
      });
  };

  return (
    <div>
      <Title className="gpt-form-title">{taskName}</Title>
      <p>Fetch patients health records.</p>
      <Form
        className="gpt-form-text"
        onFinish={handleSubmit}
        layout={"vertical"}
      >
        <Form.Item name="file_upload">
          <Upload
            beforeUpload={(file) => {
              const supportedExtensions = ["pdf", "jpg", "jpeg", "png", "tif"];
              const lowerCaseFileName = file.name.toLowerCase();
              const isSupportedFormat =
                [
                  "application/pdf",
                  "image/jpeg",
                  "image/png",
                  "image/tiff",
                ].includes(file.type) ||
                supportedExtensions.includes(
                  file.name.slice(file.name.lastIndexOf(".") + 1).toLowerCase()
                ) ||
                supportedExtensions.some((ext) =>
                  lowerCaseFileName.includes(ext)
                );

              if (!isSupportedFormat) {
                notification.error({
                  message: formatMessage(config.messages.tasks.invalid_file, {
                    file_list: "PDF, JPG/JPEG, PNG, and TIFF",
                  }),
                });
              }
              return false;
            }}
            multiple={true}
            onChange={(info) => {
              handleUpload(info);
            }}
            fileList={fileList}
            itemRender={(originNode) => {
              // Remove any tooltips
              return React.cloneElement(originNode, { title: "" });
            }}
          >
            <Button icon={<UploadOutlined />}>
              Please upload medical documents
            </Button>
          </Upload>
        </Form.Item>
        {taskType && taskType === "health_record" && (
          <Form.Item
            name="custom_field_extraction"
            label="Optionally, you can fill list of comma separated fields you want to extract!"
          >
            <Input className="gpt-form-text-area" rows={12} />
          </Form.Item>
        )}
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={waiting}
            disabled={!enabledSubmit}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TaskReferralEntry;
