import {
  Create,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  Edit,
  SimpleForm,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { CustomEditToolbar } from "./../AdminPage";

const PER_PAGE = 100;

const transformData = (data) => {
  if (!data.fax_numbers) {
    data.fax_numbers = [];
  }
  if (!data.dedicated_fax_numbers) {
    data.dedicated_fax_numbers = [];
  }
  return data;
};

export const CreateOrganization = () => {
  return (
    <Create>
      <SimpleForm>
        <TextInput
          source="org_name"
          label="Organization Name"
          placeholder="Organization Name"
        />
        <TextInput
          source="admin_name"
          label="Admin Name"
          placeholder="Admin Name"
        />
        <TextInput
          source="email"
          label="Admin Email"
          placeholder="Admin Email"
        />
        <BooleanInput
          source="send_link_to_upload"
          label="Send Link To Upload External File"
        />
        <BooleanInput
          source="allow_child_organizations"
          label="Can Add Sub Organizations"
        />
        <ArrayInput source="enabled_tasks" style={{ marginLeft: "20px" }}>
          <SimpleFormIterator>
            <ReferenceInput
              source=""
              reference="task_types"
              allowEmpty
              perPage={PER_PAGE}
            >
              <SelectInput optionText="tag" optionValue="tag" fullWidth />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="fax_numbers" style={{ marginLeft: "20px" }}>
          <SimpleFormIterator>
            <TextInput source="" label="Fax Number" fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput
          source="dedicated_fax_numbers"
          style={{ marginLeft: "20px" }}
        >
          <SimpleFormIterator>
            <TextInput source="" label="Incoming Fax Number" fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Create>
  );
};

export const EditOrganization = (props) => (
  <Edit title="Edit Organization" {...props} transform={transformData}>
    <SimpleForm toolbar={<CustomEditToolbar />}>
      <TextInput source="id" fullWidth inputProps={{ readOnly: true }} />
      <TextInput source="name" validate={required()} fullWidth />
      <ReferenceInput
        source="owner"
        reference="user_accounts"
        allowEmpty
        inputProps={{ readOnly: true }}
      >
        <SelectInput optionText="name" inputProps={{ readOnly: true }} />
      </ReferenceInput>
      <ArrayInput source="enabled_tasks" style={{ marginLeft: "20px" }}>
        <SimpleFormIterator>
          <ReferenceInput
            source=""
            reference="task_types"
            allowEmpty
            perPage={PER_PAGE}
          >
            <SelectInput optionText="tag" optionValue="tag" fullWidth />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source="fax_numbers" style={{ marginLeft: "20px" }}>
        <SimpleFormIterator>
          <TextInput source="" label="Fax Number" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="dedicated_fax_numbers" style={{ marginLeft: "20px" }}>
        <SimpleFormIterator>
          <TextInput source="" label="Incoming Fax Number" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
      <ReferenceInput
        source="parent_org_id"
        reference="organizations"
        allowEmpty
        perPage={PER_PAGE}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
