import React, { useState, useContext } from "react";
import { Form, Button, Typography, Upload, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../utils/utilities";
import ConfigContext from "../context/ConfigContext";

const { Title } = Typography;
const TaskPayorPolicyIngestion = (props) => {
  const [fileList, setFileList] = useState(props.fileList);
  const [waiting] = useState(props.waiting);
  const [enabledSubmit, setEnabledSubmit] = useState(props.enabledSubmit);
  const [tasks] = useState(props.tasks);
  const setTasks = props.setTasks;
  const handleCancel = props.handleCancel;
  const setWaiting = props.setWaiting;
  const config = useContext(ConfigContext);

  const handleUpload = ({ fileList }) => {
    setFileList(fileList);
    setEnabledSubmit(fileList.length > 0);
  };

  const clear = () => {
    setFileList([]);
    setEnabledSubmit(false);
    setWaiting(false);
    handleCancel();
  };

  const handleSubmit = async () => {
    setWaiting(true);
    let data_items = [
      {
        name: "Task Requirements",
        tag: "request-spec",
        data_type: "json",
        data: {},
      },
    ];

    fileList.forEach((file, index) => {
      data_items.push({
        tag: "upload",
        data_type: "file",
        data: index,
        file_name: file.originFileObj.name,
      });
    });

    let createTask = {
      task_type: "payor_policy_ingestion",
      data_items: data_items,
    };

    let formData = new FormData();
    formData.append("create_task", JSON.stringify(createTask));
    console.log(fileList);
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("uploads", file.originFileObj);
      });
    }
    console.log(formData);
    AuthenticatedApi.post("/api/v2/task/create", formData)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          setTasks([response.data, ...tasks]);
        }
        clear();
      })
      .catch((error) => {
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
        clear();
      });
  };

  return (
    <div>
      <Title className="gpt-form-title">Add Payor Policy</Title>
      <p></p>
      <Form
        className="gpt-form-text"
        onFinish={handleSubmit}
        layout={"vertical"}
      >
        <Form.Item name="file_upload">
          <Upload
            beforeUpload={(file) => {
              const isPdf =
                file.type === "application/pdf" || file.name.endsWith(".pdf");
              if (!isPdf) {
                notification.error({
                  message: formatMessage(config.messages.tasks.invalid_file, {
                    file_list: "PDF",
                  }),
                });
              }
              return isPdf;
            }}
            multiple={false}
            onChange={(info) => {
              if (info.fileList.length > 1) {
                notification.error({
                  message: formatMessage(config.messages.tasks.single_file_err),
                });
                return;
              }
              handleUpload(info);
            }}
            fileList={fileList}
            itemRender={(originNode) => {
              // Remove any tooltips
              return React.cloneElement(originNode, { title: "" });
            }}
          >
            <Button icon={<UploadOutlined />}>
              Please upload payor policy document
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={waiting}
            disabled={!enabledSubmit}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TaskPayorPolicyIngestion;
