import React, { useContext, useState } from "react";
import { Upload, Input, notification, Form, Typography, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { AuthenticatedApi } from "../../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../../utils/utilities";
import ConfigContext from "../../context/ConfigContext";

const { Title } = Typography;
const PayorPolicy = (props) => {
  const [form] = Form.useForm();
  const appConfig = useContext(ConfigContext);
  const { payorSpecialtyFormRef, waitingPolicy, setWaitingPolicy } = props;
  const [fileList, setFileList] = useState([]);
  const [enabledSubmit, setEnabledSubmit] = useState(false);

  const handleUpload = ({ fileList }) => {
    setFileList(fileList);
    const validTypes = ["application/pdf", "image/jpeg", "image/png"];
    const areAllFilesValid = fileList.every((file) =>
      validTypes.includes(file.type)
    );
    setEnabledSubmit(areAllFilesValid && fileList.length > 0);
  };

  const addPayorSpecialty = async (values) => {
    setWaitingPolicy(true);

    // Create a FormData object to handle file uploads
    let formData = new FormData();
    formData.append("payor_name", values.payorName);
    formData.append("clinical_specialty", values.clinicalSpecialty);
    formData.append(
      "policy_name",
      values.payorName + " " + values.clinicalSpecialty + " Policy"
    );

    if (fileList.length > 0) {
      fileList.forEach((file, index) => {
        console.log("File", index);
        formData.append("file", file.originFileObj);
      });
    }

    try {
      const response = await AuthenticatedApi.post(
        "/api/add-payor-specialty",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type to multipart/form-data
          },
        }
      );
      console.log(response);
      form.resetFields();
      setFileList([]); // Reset the file list
      notification.success({
        message: formatMessage(appConfig.messages.payor.added),
      });
    } catch (error) {
      console.error(error);
      const errorMessage = handleError(error);
      notification.error({ message: errorMessage });
    } finally {
      setWaitingPolicy(false);
    }
  };

  return (
    <div className="edit-profile-container">
      <Title className="edit-profile-title">Add Payor Policy</Title>
      <Form
        layout={"vertical"}
        form={form}
        name="addPayorSpecialty"
        onFinish={addPayorSpecialty}
        ref={payorSpecialtyFormRef}
      >
        <Form.Item
          label="Payor Name"
          name="payorName"
          rules={[
            {
              required: true,
              message: "Please input the payor name",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Clinical Specialty"
          name="clinicalSpecialty"
          rules={[
            {
              required: true,
              message: "Please input the clinical specialty",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="file_upload">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Upload
              beforeUpload={(file) => {
                const validTypes = [
                  "application/pdf",
                  "image/jpeg",
                  "image/png",
                ];
                const isFileValid = validTypes.includes(file.type);

                if (!isFileValid) {
                  notification.error({
                    message: formatMessage(
                      appConfig.messages.tasks.invalid_file,
                      { file_list: "PDF, JPEG, or PNG" }
                    ),
                  });
                }

                return false;
              }}
              onChange={(info) => {
                handleUpload(info);
              }}
              fileList={fileList}
              multiple={false}
              itemRender={(originNode) => {
                // Remove any tooltips
                return React.cloneElement(originNode, { title: "" });
              }}
            >
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
            <span style={{ marginLeft: "10px" }}>
              Upload one or more PDF, JPEG, or PNG files.
            </span>
          </div>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={waitingPolicy}
            disabled={!enabledSubmit}
          >
            Add Payor/Specialty
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PayorPolicy;
