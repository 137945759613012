const ServiceDown = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12" style={{ padding: "30px" }}>
          <h1>Service Down</h1>
          <p>
            Synthpop Service is temporarily down, please try logging in again
            later.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServiceDown;
