import { useEffect, useState, useContext } from "react";
import { notification } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import "./EditProfilePage.css";
import "../style.css";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../utils/utilities";
import ConfigContext from "../context/ConfigContext";

const ConfirmSubscriptionSingle = () => {
  const [orderId, setOrderId] = useState(null);
  const { isAuthenticated, isLoading } = useAuth0();
  const config = useContext(ConfigContext);

  useEffect(() => {
    if (window.location.search && orderId === null) {
      const params = new URLSearchParams(window.location.search);
      const orderId = params.get("order_id");
      setOrderId(orderId);
    }

    if (orderId && !isLoading && isAuthenticated) {
      AuthenticatedApi.post("/api/stripe-subscribe-new", { order_id: orderId })
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            notification.info({
              message: formatMessage(
                config.messages.confirm_subscription.return_home
              ),
            });
            let authState = JSON.parse(localStorage.getItem("authState"));
            if (authState !== null) {
              authState["role"] = response.data.role || "user";
              authState["notFound"] = false;
              localStorage.setItem("authState", JSON.stringify(authState));
            }
            window.location.href = "/tasks-list";
          } else {
            notification.error({
              message: formatMessage(
                config.messages.confirm_subscription.error
              ),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          const errorMessage = handleError(error);
          notification.error({ message: errorMessage });
        });
    }
  }, [isAuthenticated, isLoading, orderId]);
  return (
    <div>
      <p className="loading">
        Your payment is successful. Preparing your account ...
      </p>
    </div>
  );
};

export default ConfirmSubscriptionSingle;
