import { useEffect, useState, useContext } from "react";
import { Button, notification } from "antd";
import "../style.css";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuthRole } from "../context/AuthRoleContext";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../utils/utilities";
import ConfigContext from "../context/ConfigContext";
import LoadingComponent from "./LoadingComponent";

function StripeCheckoutPage() {
  const { user } = useAuth0();
  const { notFound, loaded } = useAuthRole();
  const [waiting, setWaiting] = useState(false);
  const config = useContext(ConfigContext);

  useEffect(() => {
    const checkAuth = async () => {
      if (loaded) {
        AuthenticatedApi.get("/api/user")
          .then((response) => {
            if (response.status === 200) {
              console.log(response.data.subscribed);
            } else {
              notification.error({ message: response.data.message });
            }
          })
          .catch(() => {
            notification.error({
              message: formatMessage(config.messages.user.profile_fetch_error),
            });
          });
      }
    };

    checkAuth();
  }, [user, loaded]);

  const stripeCheckout = async () => {
    setWaiting(true);
    const headers = {
      "Access-Control-Allow-Origin": "*",
    };

    AuthenticatedApi.post(
      "/api/stripe-checkout",
      {
        name: user.name,
        email: user.email,
      },
      { headers: headers }
    )
      .then((response) => {
        console.log(response.data.url);
        window.location.href = response.data.url;
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
      });
  };

  return (
    <div>
      {!loaded && <LoadingComponent />}
      {notFound && loaded && (
        <div className="loading">
          The email you entered was not found in our database. Please contact
          elad@synthpop.ai to set up an account.
        </div>
      )}

      {!notFound && loaded && (
        <Button
          style={{ margin: 30 }}
          onClick={() => stripeCheckout()}
          loading={waiting}
          type="primary"
        >
          Subscribe Checkout
        </Button>
      )}
    </div>
  );
}

export default StripeCheckoutPage;
