import { useContext } from "react";
import {
  Select,
  Checkbox,
  InputNumber,
  Input,
  notification,
  Form,
  Typography,
  Button,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { AuthenticatedApi } from "../../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../../utils/utilities";
import ConfigContext from "../../context/ConfigContext";

const { Title } = Typography;
const { Option } = Select;
const InsuranceBenefitAutomation = () => {
  const appConfig = useContext(ConfigContext);

  const handleStartInsuranceBenefitsAutomation = async (values) => {
    let formData = new FormData();
    formData.append("save_data", values.save_data);
    formData.append("max_orders", values.max_orders);
    formData.append("order_no", values.order_no);
    formData.append("filtered_wip_state", values.filtered_wip_state);
    try {
      await AuthenticatedApi.post(
        "/api/start-insurance-benefits-automation",
        formData
      );
      notification.success({
        message: formatMessage(
          appConfig.messages.tasks.insurance_automation_started
        ),
      });
    } catch (error) {
      console.error(error);
      const errorMessage = handleError(error);
      notification.error({ message: errorMessage });
    }
  };

  return (
    <div className="edit-profile-container">
      <Title className="edit-profile-title">
        Insurance Benefits Automation
      </Title>
      <Form
        name="insurance_benefits_automation"
        onFinish={handleStartInsuranceBenefitsAutomation}
        initialValues={{
          save_data: false,
          max_orders: 1,
          order_no: null,
          filtered_wip_state: "Insurance Benefits - RPA Pend 1",
        }}
      >
        <Form.Item name="save_data" valuePropName="checked">
          <Checkbox>Push data to partner API</Checkbox>
        </Form.Item>
        <Form.Item name="max_orders" label="Max Orders to Process (1-20)">
          <InputNumber min={1} defaultValue={1} />
        </Form.Item>
        <Form.Item
          name="filtered_wip_state"
          label="Filtered WIP State"
          tooltip={{
            title: "Enter the filtered WIP state",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Select
            placeholder="Select a filtered WIP state"
            style={{ width: "100%" }}
          >
            <Option value="Insurance Benefits - RPA Pend 1">
              Insurance Benefits - RPA Pend 1
            </Option>
            <Option value="Insurance Benefits - RPA Pend 1.5">
              Insurance Benefits - RPA Pend 1.5
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="order_no"
          label="Order Number (Optional)"
          tooltip={{
            title: "Enter the order number",
            icon: <InfoCircleOutlined />,
          }}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Start Insurance Benefits Automation
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default InsuranceBenefitAutomation;
