import { useRecordContext } from "react-admin";

const EllipsisTextField = ({ source }) => {
  const record = useRecordContext();
  const value = record[source];
  const truncatedValue =
    value && value.length > 100 ? `${value.substring(0, 100)}...` : value;

  return <span title={source}>{truncatedValue}</span>;
};

export default EllipsisTextField;
