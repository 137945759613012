export const CombineComponents = (...components) => {
  return components.reduce((AccumulatedComponents, CurrentComponent) => {
    // eslint-disable-next-line react/display-name
    return ({ children, ...props }) => {
      return (
        <AccumulatedComponents {...props}>
          <CurrentComponent {...props}>{children}</CurrentComponent>
        </AccumulatedComponents>
      );
    };
  });
};
