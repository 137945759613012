import {
  Create,
  Edit,
  ReferenceInput,
  SimpleForm,
  SelectInput,
} from "react-admin";

export const CreatePolicyProcedureCode = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="policy_id" reference="policies" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="procedure_code_id"
        reference="procedure_codes"
        allowEmpty
      >
        <SelectInput optionText="code_name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const EditPolicyProcedureCode = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput source="policy_id" reference="policies" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="procedure_code_id"
        reference="procedure_codes"
        allowEmpty
      >
        <SelectInput optionText="code_name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
