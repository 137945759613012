import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginPage from "./components/LoginPage";
import LogoutPage from "./components/LogoutPage";
import EditProfilePage from "./components/EditProfilePage";
import NavigationBar from "./components/NavigationBar";
import UsagePage from "./components/UsagePage";
import OrganizationPage from "./components/OrganizationPage";
import CreateOrganizationPage from "./components/CreateOrganizationPage";
import TasksList from "./components/TasksList";
import TaskShow from "./components/TaskShow";
import StripeCheckoutPage from "./components/StripeCheckoutPage";
import ConfirmSubscription from "./components/ConfirmSubscription";
import { AppContextProvider } from "./context/AppContextProvider";
import Signup from "./components/SignUp";
import ConfirmSubscriptionSingle from "./components/ConfirmSubscriptionSingle";
import TaskResubmitForm from "./components/TaskResubmitForm";
import VerifyEmail from "./components/VerifyEmail";
import AdminPage from "./components/AdminPage";
import ServiceDown from "./components/ServiceDown";
import AuthCallback from "./components/AuthCallback";
import RedirectComponent from "./components/RedirectComponent";
import WaitList from "./components/WaitList";
import LoginRedirect from "./components/LoginRedirect";
import SignUpRedirect from "./components/SignUpRedirect";
import ProtectedRoute from "./components/ProtectedRoute";
import { useTheme } from "./context/ThemeContext";

import { useAuth0 } from "@auth0/auth0-react";
import useAutoLogout from "./components/AutoLogout";
import LoadingComponent from "./components/LoadingComponent";

function NavbarWrapper(body) {
  // A wrapper function to render the navigation bar, on top of content
  // that actually requires it. -- We don't want to render the nav bar
  // on pages related to user sign-up, to avoid races between the sign
  // up completing and the nav bar checking permissions for the user,
  // to enable/disable certain options.
  return (
    <>
      <NavigationBar />
      {body}
    </>
  );
}

function AdminNavbarWrapper(body) {
  return <>{body}</>;
}

function App() {
  const { antdTheme, isDarkMode } = useTheme();
  const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0();
  const nonActivityMaxTime =
    parseInt(localStorage.getItem("autoLogoutInterval"), 10) || 300000;
  useAutoLogout(isAuthenticated, nonActivityMaxTime);
  console.log("Is Authenticated: ", isAuthenticated);

  useEffect(() => {
    // Dynamically set the document's background color based on the theme
    document.body.style.backgroundColor = isDarkMode ? "#000" : "#fff";
  }, [isDarkMode]);
  // eslint-disable-next-line no-unused-vars
  if (isLoading) {
    // Render a loading indicator or alternative UI while checking authentication status
    return <LoadingComponent />;
  }

  return (
    <Router>
      <AppContextProvider
        isAuthenticated={isAuthenticated}
        isLoading={isLoading}
        loginWithRedirect={loginWithRedirect}
        user={user}
        theme={antdTheme}
      >
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/sign_up_redirect" element={<SignUpRedirect />} />
          <Route path="/login/:orgName" element={<LoginRedirect />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/stripe-checkout" element={<StripeCheckoutPage />} />
          <Route path="/sign_up" element={<Signup />} />
          <Route path="/service_down" element={<ServiceDown />} />
          <Route
            path="/confirm-subscription-single"
            element={<ConfirmSubscriptionSingle />}
          />
          <Route path="/auth-callback" element={<AuthCallback />} />
          <Route
            path="/account"
            element={
              isAuthenticated ? (
                NavbarWrapper(<EditProfilePage />)
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/confirm-subscription"
            element={
              isAuthenticated ? (
                <ConfirmSubscription />
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/usage"
            element={
              isAuthenticated ? (
                NavbarWrapper(<UsagePage />)
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/organization"
            element={
              isAuthenticated ? (
                NavbarWrapper(<OrganizationPage />)
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/create-organization"
            element={
              isAuthenticated ? (
                NavbarWrapper(<CreateOrganizationPage />)
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/tasks-list"
            element={
              isAuthenticated ? (
                NavbarWrapper(<TasksList />)
              ) : (
                <Navigate to="/" replace />
              )
            }
          />
          <Route
            path="/tasks-list/:taskId"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                {NavbarWrapper(<TaskShow />)}
              </ProtectedRoute>
            }
          />
          <Route
            path="/tasks/:taskId/resubmit"
            element={NavbarWrapper(<TaskResubmitForm />)}
          />
          <Route path="/verify_email" element={<VerifyEmail />} />
          <Route path="/job/:shortHash" element={<RedirectComponent />} />
          <Route path="/waitlist" element={<WaitList />} />
          <Route
            exact
            path="/admin/*"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                {AdminNavbarWrapper(<AdminPage />)}
              </ProtectedRoute>
            }
          />
        </Routes>
      </AppContextProvider>
    </Router>
  );
}

export default App;
