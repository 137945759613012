import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { initApi } from "./utils/MyApi";
import reportWebVitals from "./reportWebVitals";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Auth0Provider } from "@auth0/auth0-react";
import { ThemeProvider } from "./context/ThemeContext";
import { ConfigProvider } from "./context/ConfigContext";
import LoadingComponent from "./components/LoadingComponent";

function AppInitializer() {
  const [appConfig, setAppConfig] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    async function loadConfig() {
      const config = await initApi();
      setAppConfig(config);
      setStripePromise(loadStripe(config.stripe_api_public_key));
    }

    loadConfig();
  }, []);

  if (!appConfig || !stripePromise) {
    return <LoadingComponent />;
  }

  return (
    <React.StrictMode>
      <ConfigProvider value={appConfig}>
        <Elements stripe={stripePromise}>
          <Auth0Provider
            domain={appConfig.auth0_domain}
            clientId={appConfig.auth0_client_id}
            authorizationParams={{
              redirect_uri: window.location.origin + "/auth-callback",
            }}
            cacheLocation="localstorage"
          >
            <ThemeProvider>
              <App />
            </ThemeProvider>
          </Auth0Provider>
        </Elements>
      </ConfigProvider>
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<AppInitializer />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
