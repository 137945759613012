import {
  Create,
  List,
  Datagrid,
  TextField,
  BooleanField,
  Edit,
  required,
  SimpleForm,
  TextInput,
  BooleanInput,
  CreateButton,
  ExportButton,
  TopToolbar,
} from "react-admin";
import EllipsisTextField from "./../EllipsisTextField";
import { ImportButton } from "react-admin-import-csv";
import { CustomEditToolbar } from "./../AdminPage";

const transformImportedData = (action, data) => {
  console.log("Transforming Data", action, data);
  return data;
};

const MyImportButton = (props) => (
  <ImportButton
    {...props}
    parseConfig={{ header: true, dynamicTyping: true, skipEmptyLines: true }}
    preCommitCallback={transformImportedData}
  />
);

const ListActions = (props) => {
  // eslint-disable-next-line react/prop-types
  const { className } = props;
  return (
    <TopToolbar className={className}>
      <CreateButton />
      <ExportButton />
      <MyImportButton {...props} />
    </TopToolbar>
  );
};

// List Prompt Section
export const PromptList = (props) => (
  <List {...props} actions={<ListActions />}>
    <Datagrid rowClick="edit">
      <TextField source="short_name" />
      <TextField source="task_type" />
      <TextField source="timestamp" />
      <TextField source="engine" />
      <TextField source="temperature" />
      <BooleanField source="stream" />
      <BooleanField source="released" />
      <EllipsisTextField source="context" />
      <EllipsisTextField source="prompt" />
      <TextField source="org_id" />
    </Datagrid>
  </List>
);

// Create Prompt Section
export const PromptCreate = (props) => (
  <Create title="Create a Prompt" {...props}>
    <SimpleForm>
      <TextInput source="short_name" validate={required()} fullWidth />
      <TextInput source="task_type" validate={required()} fullWidth />
      <TextInput source="engine" validate={required()} fullWidth />
      <TextInput source="temperature" validate={required()} fullWidth />
      <BooleanInput source="stream" />
      <BooleanInput source="released" />
      <TextInput source="context" multiline validate={required()} fullWidth />
      <TextInput source="prompt" multiline validate={required()} fullWidth />
      <TextInput source="org_id" fullWidth />
    </SimpleForm>
  </Create>
);

export const PromptEdit = (props) => (
  <Edit title="Edit Prompt" {...props}>
    <SimpleForm toolbar={<CustomEditToolbar />}>
      <TextInput disabled source="id" fullWidth />
      <TextInput source="short_name" validate={required()} fullWidth />
      <TextInput source="task_type" validate={required()} fullWidth />
      <TextInput source="engine" validate={required()} fullWidth />
      <TextInput source="temperature" validate={required()} fullWidth />
      <BooleanInput source="stream" />
      <BooleanInput source="released" />
      <TextInput source="context" multiline fullWidth />
      <TextInput source="prompt" multiline validate={required()} fullWidth />
      <TextInput source="org_id" fullWidth />
    </SimpleForm>
  </Edit>
);
