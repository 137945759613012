import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "antd";
import { Navigate, useLocation } from "react-router-dom";
import "../style.css";

const LoginPage = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();

  const redirectUri = location.state?.from?.pathname || "/tasks-list";

  // redirect to /clinical-eligibility if already logged in
  if (!isLoading && isAuthenticated) {
    return <Navigate to={redirectUri} replace />;
  }

  return (
    <div>
      <Button
        style={{ margin: 30 }}
        onClick={() =>
          loginWithRedirect({
            appState: { returnTo: redirectUri },
          })
        }
        type="primary"
      >
        Log In
      </Button>
      <Button
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              screen_hint: "signup",
            },
            appState: { returnTo: redirectUri },
          })
        }
        type="primary"
      >
        Sign up
      </Button>
    </div>
  );
};

export default LoginPage;
