import { useEffect } from "react";
import { UnAuthenticatedApi } from "../utils/MyApi";
import { useParams } from "react-router-dom";

const RedirectComponent = () => {
  const { shortHash } = useParams();
  useEffect(() => {
    const fetchAndRedirect = async () => {
      try {
        const response = await UnAuthenticatedApi.get(`/api/job/${shortHash}`);
        window.location.href = response.data.url; // redirect to the actual URL
      } catch (error) {
        console.error("Error fetching the long URL:", error);
      }
    };

    fetchAndRedirect();
  }, [shortHash]);

  return <div>Redirecting...</div>;
};

export default RedirectComponent;
