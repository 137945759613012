import { Table } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const DocumentTable = (props) => {
  const dataSource = props.dataSource;
  const fileData = props.fileData;

  const getFileDataIndex = (docName) => {
    const index = fileData.findIndex((data) => data.name === docName);
    if (index === -1) {
      return -1;
    }
    return index + 1;
  };

  const formatPageNumbers = (pages) => {
    if (!pages || pages.length === 0) return "";

    pages = pages.map((page) => page + 1);
    const sortedPages = [...new Set(pages)].sort((a, b) => a - b);
    const ranges = [];
    let rangeStart = sortedPages[0];
    let rangeEnd = rangeStart;

    for (let i = 1; i < sortedPages.length; i++) {
      const currentPage = sortedPages[i];
      if (currentPage === rangeEnd + 1) {
        rangeEnd = currentPage;
      } else {
        ranges.push(
          rangeStart !== rangeEnd
            ? `${rangeStart}-${rangeEnd}`
            : `${rangeStart}`
        );
        rangeStart = rangeEnd = currentPage;
      }
    }

    ranges.push(
      rangeStart !== rangeEnd ? `${rangeStart}-${rangeEnd}` : `${rangeStart}`
    );

    return ranges.join(", ");
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Meets Requirements",
      dataIndex: "meet_requirements",
      key: "meet_requirements",
      render: (text, record) => {
        switch (record.meet_requirements) {
          case "yes":
            return <CheckOutlined style={{ color: "green" }} />;
          case "no":
            return <CloseOutlined style={{ color: "red" }} />;
          case "na":
            return <></>;
          default:
            return <></>;
        }
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Document No",
      dataIndex: "doc_no",
      key: "doc_no",
      render: (text, record) => {
        if (record.doc_name !== undefined) {
          return getFileDataIndex(record.doc_name);
        }
      },
    },
    {
      title: "Pages",
      dataIndex: "pages",
      key: "pages",
      render: (pages) => formatPageNumbers(pages),
    },
  ];

  return <Table dataSource={dataSource} columns={columns} />;
};

export default DocumentTable;
