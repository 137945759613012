import React, { useState, useContext } from "react";
import { Form, Button, Typography, Upload, notification, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../utils/utilities";
import ConfigContext from "../context/ConfigContext";

const { Title } = Typography;
const { Option } = Select;
const TaskGeneticTesting = (props) => {
  const [fileList, setFileList] = useState(props.fileList);
  const [waiting] = useState(props.waiting);
  const [enabledSubmit, setEnabledSubmit] = useState(props.enabledSubmit);
  const [tasks] = useState(props.tasks);
  const setTasks = props.setTasks;
  const handleCancel = props.handleCancel;
  const setWaiting = props.setWaiting;
  const [form] = Form.useForm();
  const config = useContext(ConfigContext);

  const handleUpload = ({ fileList }) => {
    setFileList(fileList);
    const validTypes = ["application/pdf", "image/jpeg", "image/png"];
    const areAllFilesValid = fileList.every((file) =>
      validTypes.includes(file.type)
    );
    setEnabledSubmit(areAllFilesValid && fileList.length > 0);
  };

  const clear = () => {
    setFileList([]);
    setEnabledSubmit(false);
    setWaiting(false);
    handleCancel();
  };

  const handleSubmit = async (values) => {
    setWaiting(true);
    let data_items = [
      {
        name: "Task Requirements",
        tag: "request-spec",
        data_type: "json",
        data: {
          service_type: values["patient_service"],
          selection: values["payer_selection"],
        },
      },
    ];

    fileList.forEach((file, index) => {
      data_items.push({
        tag: "upload",
        data_type: "file",
        data: index,
        file_name: file.originFileObj.name,
      });
    });

    let createTask = {
      task_type: "genetic_testing",
      data_items: data_items,
    };

    let formData = new FormData();
    formData.append("create_task", JSON.stringify(createTask));
    console.log(fileList);
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("uploads", file.originFileObj);
      });
    }
    console.log(formData);
    AuthenticatedApi.post("/api/v2/task/create", formData)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          setTasks([response.data, ...tasks]);
        }
        clear();
      })
      .catch((error) => {
        console.error("Error generating letter: ", error);
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
        clear();
      });
  };

  // Define your initial values for both generic and non-generic forms
  const geneticInitialValues = {
    formType: "generic",
    patient_service: `0237U - Targeted variant genotyping ANK2, CASQ2, CAV3, KCNE1, KCNE2, KCNH2, KCNJ2, KCNQ1, RYR2, and SCN5A`,
    payer_selection: `CMS`,
  };

  return (
    <>
      <br />
      <br />
      <Form
        form={form}
        className="gpt-form-text"
        onFinish={handleSubmit}
        layout={"vertical"}
        initialValues={geneticInitialValues}
      >
        <Title className="gpt-form-title">Genetic Test Preauthorization</Title>
        <p className="note">
          assess patient clinical necessity for a service, given their payor
          policy and their clinical chart.
        </p>
        <Form.Item
          name="patient_service"
          label="Patient Service"
          rules={[{ required: true }]}
        >
          <Select placeholder="Select a patient service">
            <Option value="0237U - Targeted variant genotyping ANK2, CASQ2, CAV3, KCNE1, KCNE2, KCNH2, KCNJ2, KCNQ1, RYR2, and SCN5A">
              0237U - Targeted variant genotyping ANK2, CASQ2, CAV3, KCNE1,
              KCNE2, KCNH2, KCNJ2, KCNQ1, RYR2, and SCN5A
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="payer_selection"
          label="Payor Selection"
          rules={[{ required: true }]}
        >
          <Select placeholder="Select a payor selection">
            <Option value="CMS">CMS</Option>
          </Select>
        </Form.Item>
        <Form.Item name="file_upload">
          <div style={{ display: "flex", alignItems: "center" }}>
            <Upload
              beforeUpload={(file) => {
                const validTypes = [
                  "application/pdf",
                  "image/jpeg",
                  "image/png",
                ];
                const isFileValid = validTypes.includes(file.type);

                if (!isFileValid) {
                  notification.error({
                    message: formatMessage(config.messages.tasks.invalid_file, {
                      file_list: "PDF, JPEG, or PNG",
                    }),
                  });
                }

                return false;
              }}
              onChange={(info) => {
                handleUpload(info);
              }}
              fileList={fileList}
              itemRender={(originNode) => {
                // Remove any tooltips
                return React.cloneElement(originNode, { title: "" });
              }}
            >
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
            <span style={{ marginLeft: "10px" }}>
              Upload one or more PDF, JPEG, or PNG files.
            </span>
          </div>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={waiting}
            disabled={!enabledSubmit}
          >
            Generate Preauthorization Letter
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default TaskGeneticTesting;
