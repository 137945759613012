import { useState, useEffect, useContext } from "react";
import { Table, Button, Modal, notification, Space } from "antd";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import CopyIconToClipboard from "./CopyIconToClipboard";
import ApiSecretDialog from "./ApiSecretDialog";
import ConfigContext from "../context/ConfigContext";
import { formatMessage } from "../utils/utilities";

const ApiKeysTable = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [selectedToken, setSelectedToken] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [creatingApiSecret, setCreatingApiSecret] = useState(false);
  const [apiSecretShown, setApiSecretShown] = useState(null);
  const config = useContext(ConfigContext);

  const fetchApiKeys = async () => {
    try {
      const response = await AuthenticatedApi.get("/api/organization/api-keys");
      setApiKeys(response.data.api_keys);
    } catch (error) {
      console.error("Error fetching API keys:", error);
    }
  };

  useEffect(() => {
    fetchApiKeys();
  }, []);

  const handleShowToken = async (apiKeyId) => {
    try {
      const response = await AuthenticatedApi.get(
        `/api/organization/api-keys/${apiKeyId}`
      );
      setSelectedToken(response.data);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error fetching API key:", error);
    }
  };

  const handleCreateApiSecret = async () => {
    setCreatingApiSecret(true);
    try {
      const response = await AuthenticatedApi.post(
        "/api/organization/create-api-secret"
      );
      setApiSecretShown(response.data.secret);
    } catch (error) {
      console.error("Error creating API key:", error);
      notification.error({
        message: formatMessage(config.messages.api_keys.key_created_failure),
      });
    } finally {
      setCreatingApiSecret(false);
    }
  };

  const columns = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "User Id", dataIndex: "user_id", key: "user_id" },
    { title: "Created At", dataIndex: "create_date", key: "create_date" },
    { title: "Expire At", dataIndex: "expire_date", key: "expire_date" },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <Button onClick={() => handleShowToken(record.id)}>Show</Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div>
        <h2>API Secret</h2>
        <div>
          Use this button to create a new API Secret. The API secret is used to
          refresh Synthpop API access tokens.
        </div>
        <div>
          <b>
            Keep the API secret confidential. Synthpop does not store the token
            and it will only be shown once.
          </b>
        </div>
        <Button
          type="primary"
          onClick={handleCreateApiSecret}
          loading={creatingApiSecret}
          style={{ marginBottom: 16, marginTop: 10 }}
        >
          Create API Secret
        </Button>
      </div>
      <div>
        <h2> Legacy API Keys</h2>
        <Table dataSource={apiKeys} columns={columns} rowKey="id" />
        <Modal
          title=""
          open={isModalVisible}
          onOk={() => setIsModalVisible(false)}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          {selectedToken && (
            <>
              <CopyIconToClipboard
                key={selectedToken.key}
                title="JWT Token"
                fieldType="text"
                details={selectedToken.token}
                isEditable={false}
              />
            </>
          )}
        </Modal>
      </div>

      <Modal
        title=""
        open={apiSecretShown}
        onOk={() => setApiSecretShown(false)}
        onCancel={() => setApiSecretShown(false)}
        footer={null}
      >
        <>
          <ApiSecretDialog title="API Secret" secret={apiSecretShown} />
        </>
      </Modal>
    </div>
  );
};

export default ApiKeysTable;
