import { useState, useContext, useEffect, useRef } from "react";
import {
  Select,
  Upload,
  notification,
  Form,
  Typography,
  Button,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { AuthenticatedApi } from "../../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../../utils/utilities";
import ConfigContext from "../../context/ConfigContext";

const { Title } = Typography;
const { Option } = Select;
const UploadTaskFolder = (props) => {
  const [uploadFolderForm] = Form.useForm();
  const appConfig = useContext(ConfigContext);
  const { availableTasks, role, payorSpecialtyFormRef } = props;
  const [healthRecordFiles, setHealthRecordFiles] = useState([]);
  const [directoryName, setDirectoryName] = useState("");
  const [accumulatedFiles, setAccumulatedFiles] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(Date.now());
  const [waitingHealthRecord, setWaitingHealthRecord] = useState(false);
  const [uploadQueue, setUploadQueue] = useState([]);
  const processingRef = useRef(false);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const createHealthRecords = async (values) => {
    setWaitingHealthRecord(true);

    // Define the size of each batch
    const BATCH_SIZE = 2;
    const DELAY_MS = 1000; // Delay in milliseconds between batches

    // Split the files into batches
    const batches = [];
    for (let i = 0; i < accumulatedFiles.length; i += BATCH_SIZE) {
      batches.push(accumulatedFiles.slice(i, i + BATCH_SIZE));
    }

    // Process each batch
    for (const batch of batches) {
      await Promise.all(
        batch.map(async (file) => {
          try {
            await createHealthRecord(file, values["task_selection"]);
          } catch (error) {
            // Handle error (e.g., log it or notify the user)
            console.error("Error uploading file:", error);
          }
        })
      );

      // Wait for the specified delay time before processing the next batch
      await delay(DELAY_MS);
    }

    notification.success({
      message: formatMessage(appConfig.messages.tasks.bulk_created, {
        task_type: values["task_selection"],
      }),
    });
    setWaitingHealthRecord(false);
    setUploadQueue((prevQueue) => prevQueue.slice(1)); // Remove the processed folder from the queue
    processingRef.current = false; // Mark the processing as done

    // Process the next folder in the queue if any
    if (uploadQueue.length > 1) {
      startNextUpload();
    }
  };

  const createHealthRecord = async (file, task_type) => {
    let data_items = [
      {
        name: "Task Requirements",
        tag: "request-spec",
        data_type: "json",
        data: {},
      },
    ];

    data_items.push({
      tag: "upload",
      data_type: "file",
      data: 0,
      file_name: file.originFileObj.name,
    });

    let createTask = {
      task_type: task_type,
      data_items: data_items,
    };

    try {
      let formData = new FormData();
      formData.append("create_task", JSON.stringify(createTask));
      formData.append("uploads", file.originFileObj);

      const response = await AuthenticatedApi.post(
        "/api/v2/task/create",
        formData
      );
      console.log(response.data);
    } catch (error) {
      const errorMessage = handleError(error);
      notification.error({ message: errorMessage });
    }
  };

  const handleFolderUpload = ({ fileList }) => {
    const allowedFileTypes = [
      "application/pdf",
      "image/png",
      "image/jpeg",
      "image/tiff",
    ];
    const newImageFiles = fileList.filter(
      (file) =>
        !accumulatedFiles.some((f) => f.uid === file.uid) &&
        allowedFileTypes.includes(file.type)
    );

    if (newImageFiles.length > 0) {
      const newAccumulatedFiles = [...accumulatedFiles, ...newImageFiles];
      setAccumulatedFiles(newAccumulatedFiles);
      setLastUpdated(Date.now());
    }
  };

  const startNextUpload = () => {
    if (uploadQueue.length > 0 && !processingRef.current) {
      processingRef.current = true;
      const nextUpload = uploadQueue[0];
      setAccumulatedFiles(nextUpload.files);
      uploadFolderForm.setFieldsValue(nextUpload.values);
      uploadFolderForm.submit();
    }
  };

  useEffect(() => {
    const delay = 1000; // Delay in milliseconds
    const timer = setTimeout(() => {
      if (accumulatedFiles.length > 0 && Date.now() - lastUpdated >= delay) {
        console.log("All files uploaded!");
        setHealthRecordFiles(accumulatedFiles);
        if (accumulatedFiles.length > 0) {
          const directoryPath =
            accumulatedFiles[0].originFileObj.webkitRelativePath ||
            accumulatedFiles[0].name;
          setDirectoryName(directoryPath.split("/")[0]);
          setUploadQueue((prevQueue) => [
            ...prevQueue,
            {
              files: accumulatedFiles,
              values: uploadFolderForm.getFieldsValue(),
            },
          ]);
          uploadFolderForm.submit();
        }
      }
    }, delay);

    return () => clearTimeout(timer);
  }, [lastUpdated, accumulatedFiles, uploadFolderForm]);

  useEffect(() => {
    if (!processingRef.current && uploadQueue.length > 1) {
      startNextUpload();
    }
  }, [uploadQueue]);

  return (
    <div className="edit-profile-container">
      <Title className="edit-profile-title">Upload Tasks Folder</Title>
      <Spin spinning={waitingHealthRecord}>
        <Form
          layout={"vertical"}
          form={uploadFolderForm}
          name="addPayorSpecialty"
          onFinish={createHealthRecords}
          ref={payorSpecialtyFormRef}
        >
          <Form.Item
            name="task_selection"
            label="Task Selection"
            rules={[{ required: true }]}
          >
            <Select placeholder="Select a task type">
              {availableTasks.map(
                (task, index) =>
                  task.allowed_roles.includes(role) && (
                    <Option key={index} value={task.task_key}>
                      {task.task_name}
                    </Option>
                  )
              )}
            </Select>
          </Form.Item>
          <Form.Item name="file_upload">
            <span style={{ marginLeft: "10px" }}></span>
            <div
              className="directory-upload-wrapper"
              style={{
                display: "flex",
                alignItems: "center",
                width: "100% !important",
              }}
            >
              <Upload
                beforeUpload={(file) => {
                  console.log("beforeUpload", file);
                  return false;
                }}
                onChange={(info) => {
                  handleFolderUpload(info);
                }}
                fileList={healthRecordFiles}
                directory={true}
                itemRender={() => {
                  // Remove any tooltips
                  return <></>;
                }}
              >
                <Button
                  className="select-directory"
                  icon={<UploadOutlined />}
                  disabled={directoryName !== ""}
                >
                  Select a Folder
                </Button>
              </Upload>
            </div>
          </Form.Item>
          <div>
            {directoryName && (
              <div>
                <h3>Selected Folder: {directoryName}</h3>
                <p>
                  Each pdf file from the selected folder starts a health record
                  task
                </p>
              </div>
            )}
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default UploadTaskFolder;
