import { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  notification,
  List,
  Spin,
} from "antd";
import { UpOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { handleError } from "../utils/utilities";
import { useAuthRole } from "../context/AuthRoleContext";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "./LoadingComponent";
import "./SignUp.css";
import "../style.css";

const { Title } = Typography;
const Signup = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const { notFound, loaded } = useAuthRole();
  const [userData, setUserData] = useState(null);
  const [waitingSubscribe, setWaitingSubscribe] = useState(false);
  const [signedAgreement, setSignedAgreement] = useState(false);
  const [signedAgreementWaiting, setSignedAgreementWaiting] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [showList, setShowList] = useState(false); // hide list after signing agreement
  const navigate = useNavigate();

  const signingUrl =
    "https://synthpop.na4.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhDLsHUfoG9HciAD0wSipazifYOalvd8A7hi1B2GTBvIJtKV0Ignb76X0lHpnAEctaE*";
  const data = [
    "You have just provided us your name and organization.",
    "Next, you will sign our Master Services Agreement.",
    "Finally, you set up a monthly subscription payment with Stripe.",
  ];

  const steps = [
    {
      title: "Start here, Click [Continue]",
      icon: <UpOutlined />,
    },
    {
      title: "Sign Master Services Agreement",
    },
    {
      title: "Complete payment with Stripe",
    },
    {
      title: "Check email to validate signature",
    },
  ];

  const onFinish = (values) => {
    setUserData(values);
    setSignedAgreementWaiting(true);
  };

  const handleSignAgreement = () => {
    setSignedAgreement(true);
    setSignedAgreementWaiting(false);
    // set signing timestamp in user data
    const newUserData = userData;
    newUserData["signing_timestamp"] = new Date().toISOString();
    setUserData(newUserData);
    setWaitingSubscribe(true);
    setTimeout(() => {
      subscribe();
    }, 5000);
  };

  const subscribe = () => {
    setWaitingSubscribe(true);
    AuthenticatedApi.post("/api/stripe-checkout-new", userData)
      .then((response) => {
        console.log(response.data.url);
        window.location.href = response.data.url;
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
      });
  };

  const receiveMessage = (event) => {
    if (event.origin === "https://synthpop.na4.documents.adobe.com") {
      console.log("message received: ", event);
      var data = JSON.parse(event.data);
      console.log(data);
      if (data.type === "ESIGN") {
        setIsButtonEnabled(true);
      } else if (data.type === "ANALYTICS") {
        setShowList(false);
      } else if (data.type === "PAGE_LOAD") {
        setShowList(true);
      }
    }
  };

  const StepList = () => (
    <List
      size="small"
      dataSource={steps}
      style={{ width: "20%", marginLeft: "60%", marginTop: "-60px" }}
      renderItem={(item, index) => (
        <List.Item>
          <span
            style={
              index === 0 ? { backgroundColor: "red", padding: "5px" } : {}
            }
          >
            {item.title} {item.icon && item.icon}
          </span>
        </List.Item>
      )}
    />
  );

  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
  }, []);

  return (
    <div>
      {isLoading && <LoadingComponent />}
      {!isLoading && !isAuthenticated && loginWithRedirect()}
      {loaded && !notFound && navigate("/", { replace: true })}
      {!userData &&
        isAuthenticated &&
        !signedAgreementWaiting &&
        !signedAgreement && (
          <div className="edit-profile-container">
            <Title className="edit-profile-title">Create Account</Title>
            <p>Create your account by providing your name and organization</p>
            <Form
              layout={"vertical"}
              name="editProfile"
              initialValues={{
                name: user.name,
                email: user.email,
                org_name: "",
              }}
              onFinish={onFinish}
            >
              <Form.Item
                className="edit-profile-text"
                label="Name"
                name="name"
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                className="edit-profile-text"
                label="Email"
                name="email"
                style={{ display: "none" }}
              >
                <Input type="hidden" />
              </Form.Item>
              <Form.Item
                className="edit-profile-text"
                label="Organization Name"
                name="org_name"
                rules={[
                  {
                    required: true,
                    message: "Please input your Organization!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <List
                size="small"
                dataSource={data}
                renderItem={(item) => (
                  <List.Item>
                    <span style={{ marginLeft: "8px" }}>&#8226;</span> {item}
                  </List.Item>
                )}
              />

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit To Sign Agreement
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      {signedAgreementWaiting && (
        <div>
          {showList && (
            <div
              style={{
                width: "100%",
                height: "500px",
                position: "fixed",
                backgroundColor: "black",
              }}
            >
              <Spin
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </div>
          )}
          <iframe
            title="DocuSign Signing Interface"
            src={signingUrl}
            width="100%"
            height="600px" // You can adjust the height as needed
            frameBorder="0"
          ></iframe>
          <Button
            type="primary"
            disabled={!isButtonEnabled}
            htmlType="submit"
            onClick={() => handleSignAgreement()}
          >
            Click to Pay after Signing
          </Button>
          {showList && <StepList />}
        </div>
      )}
      {userData && signedAgreement && isAuthenticated && user && (
        <div className="edit-profile-container">
          <Title className="edit-profile-title">
            Redirecting you to stripe{" "}
          </Title>
          <div>
            <div>
              <h4>
                to subscribe for unlimited use of Synthpop's services for $99
                monthly...
              </h4>
              <Button
                type="primary"
                htmlType="submit"
                loading={waitingSubscribe}
                onClick={() => subscribe()}
              >
                Subscribe
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Signup;
