import { useState, useEffect, useContext } from "react";
import { Row, notification } from "antd";
import { useParams } from "react-router-dom";
import TaskDetails from "./TaskDetails";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";

import { useAuthRole } from "../context/AuthRoleContext";
import { ingestTaskDetails, formatMessage } from "../utils/utilities";
import ConfigContext from "../context/ConfigContext";
const TaskShow = () => {
  const { taskId } = useParams();
  const { faxNumbers, role, loaded } = useAuthRole();
  const [activeKey, setActiveKey] = useState("1");
  const [selectedData, setSelectedData] = useState(null);
  const config = useContext(ConfigContext);

  useEffect(() => {
    if (loaded && taskId && selectedData === null) {
      AuthenticatedApi.get(`/api/tasks/${taskId}`)
        .then((response) => {
          const record = response.data;
          console.log("TaskShow: ", record);
          setSelectedData(ingestTaskDetails(record, response.data));
        })
        .catch((error) => {
          console.log("Error fetching additional data:", error);
          notification.error({
            message: formatMessage(config.messages.misc.generic),
          });
        });
    }
  }, [loaded, taskId]);

  return (
    <div style={{ padding: "50px" }}>
      {selectedData && (
        <div>
          <Row>
            <span style={{ fontSize: "18px" }}>
              {selectedData && selectedData.selectedRow
                ? selectedData.selectedRow.name
                : "Results "}
            </span>
          </Row>
          <Row>
            <TaskDetails
              selectedData={selectedData}
              role={role}
              activeKey={activeKey}
              setActiveKey={setActiveKey}
              faxNumbers={faxNumbers}
            />
          </Row>
        </div>
      )}
    </div>
  );
};

export default TaskShow;
