import { useState, useEffect } from "react";
import { Modal, Select, Input, Tabs, Button } from "antd";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";

const { Option } = Select;
const { TextArea } = Input;

const ReRunModal = ({
  visible,
  onClose,
  task,
  refreshResubmittedTask,
  keepExistingType = false,
}) => {
  const [phases, setPhases] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [prompts, setPrompts] = useState([]);
  const [editedPrompts, setEditedPrompts] = useState({});
  const [okDisabled, setOkDisabled] = useState(false);

  useEffect(() => {
    if (visible) {
      fetchPhases();
    }
  }, [visible]);

  const fetchPhases = async () => {
    // Replace URL with your API endpoint
    const response = await AuthenticatedApi.get(`/api/tasks/${task.id}/phases`);
    const data = await response.data;
    setPhases(data.phases);
    setSelectedPhase(null);
    setPrompts([]);
  };

  const handlePhaseChange = async (phaseId) => {
    setSelectedPhase(phaseId);
    try {
      const response = await AuthenticatedApi.get(
        `/api/tasks/${task.id}/phases/${phaseId}/prompts`
      );
      const data = await response.data;
      setPrompts(data.prompts);
    } catch (error) {
      console.error("Error fetching prompts", error);
      setPrompts([]);
    }
  };

  const handlePromptChange = (id, short_name, text) => {
    setPrompts((currentPrompts) =>
      currentPrompts.map((prompt) =>
        prompt.id === id ? { ...prompt, prompt: text } : prompt
      )
    );
    setEditedPrompts((currentEdited) => ({
      ...currentEdited,
      [selectedPhase]: {
        ...(currentEdited[selectedPhase] || {}), // Maintain existing phase edits
        prompts: {
          ...(currentEdited[selectedPhase]?.prompts || {}),
          [short_name]: { id, prompt: text }, // Update this prompt
        },
      },
    }));
  };

  const rerunTask = async (taskId) => {
    setOkDisabled(true);
    let payload = Object.keys(editedPrompts).length
      ? editedPrompts
      : { user_edited_prompts: null };
    payload.keep_existing_type = keepExistingType;
    try {
      const response = await AuthenticatedApi.post(
        `/api/tasks/${taskId}/rerun`,
        payload
      );
      console.log("Task re-run successfully", response.data);
      refreshResubmittedTask(taskId, true);
      onClose(); // Close modal on successful submission
      setOkDisabled(false);
    } catch (error) {
      console.error("Error re-running task", error);
    }
  };

  const tabItems = prompts.map((prompt, index) => ({
    label: prompt.short_name,
    key: `${task.id}:${prompt.id}:${index + 1}`,
    children: (
      <div style={{ marginBottom: "10px" }}>
        <p>{prompt.short_name}</p>
        <TextArea
          rows={8}
          value={prompt.prompt}
          onChange={(e) =>
            handlePromptChange(prompt.id, prompt.short_name, e.target.value)
          }
        />
      </div>
    ),
  }));

  return (
    <Modal
      title={`Re-run Task: ${task.name}`}
      open={visible}
      onCancel={onClose}
      onOk={() => rerunTask(task.id)}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="ok"
          type="primary"
          onClick={() => rerunTask(task.id)}
          disabled={okDisabled}
        >
          OK
        </Button>,
      ]}
    >
      <p>Select the phase to re-run:</p>
      <Select
        style={{ width: "100%" }}
        placeholder="Select a phase, or click Ok to run unchanged"
        onChange={handlePhaseChange}
        value={selectedPhase}
      >
        {phases.map((phase) => (
          <Option key={phase.id} value={phase.id}>
            {phase.phase}
          </Option>
        ))}
      </Select>
      <div style={{ marginTop: "20px" }}>
        <Tabs defaultActiveKey="1" type="card" items={tabItems} />
      </div>
    </Modal>
  );
};

export default ReRunModal;
