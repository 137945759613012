/* eslint-disable no-unused-vars */
import { useRef } from "react";
import { Input } from "antd";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  handleSave,
  handleNameChange,
  ...restProps
}) => {
  const inputRef = useRef();

  const save = (e, key) => {
    e.stopPropagation();
    const value = inputRef.current.input.value;
    handleSave(e, key, dataIndex, value);
  };

  return (
    <td {...restProps}>
      {editing ? (
        <div>
          <Input
            ref={inputRef}
            onClick={(e) => e.stopPropagation()}
            onPressEnter={(e) => save(e, record.id)}
            onChange={handleNameChange} // Handle input value change
          />
        </div>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
