import { useContext } from "react";
import {
  Space,
  Row,
  Col,
  Input,
  notification,
  Form,
  Typography,
  Button,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { AuthenticatedApi } from "../../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../../utils/utilities";
import ConfigContext from "../../context/ConfigContext";

const { Title } = Typography;
const CustomFieldEditor = (props) => {
  const [form] = Form.useForm();
  const appConfig = useContext(ConfigContext);
  const { customFieldsInitValues, setWaitingPolicy } = props;

  const onFinish = async (values) => {
    console.log("Received values of form:", values);
    let formData = new FormData();
    formData.append("custom_fields", JSON.stringify(values.fields));
    try {
      await AuthenticatedApi.post("/api/add-custom-fields", formData);
      form.resetFields();
      notification.success({
        message: formatMessage(appConfig.messages.tasks.custom_fields_added),
      });
    } catch (error) {
      console.error(error);
      const errorMessage = handleError(error);
      notification.error({ message: errorMessage });
    } finally {
      setWaitingPolicy(false);
    }
  };

  return (
    <div className="custom-field-container">
      <Title className="edit-profile-title">Custom Fields</Title>
      <Form
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.List
          name="fields"
          initialValue={customFieldsInitValues} // Initial values
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Row gutter={16} style={{ width: "100%" }}>
                    <Col span={8}>
                      {" "}
                      {/* 30% of the container width */}
                      <Form.Item
                        {...restField}
                        name={[name, "field_name"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing name of the field",
                          },
                        ]}
                      >
                        <Input
                          className="white-placeholder"
                          placeholder="Age"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={14}>
                      {" "}
                      {/* 70% of the container width */}
                      <Form.Item
                        {...restField}
                        name={[name, "example_field"]}
                        rules={[
                          {
                            required: true,
                            message: "Missing example of the field",
                          },
                        ]}
                      >
                        <Input
                          className="white-placeholder"
                          placeholder="31, 45 ,75 (Age in years)"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                      <MinusCircleOutlined
                        style={{ marginTop: "5px" }}
                        onClick={() => remove(name)}
                      />
                    </Col>
                  </Row>
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Add
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CustomFieldEditor;
