/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import {
  LeftOutlined,
  RightOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Spin,
  Alert,
  Input,
  Select,
  notification,
  DatePicker,
} from "antd";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import dayjs from "dayjs";
import { TIFFViewer } from "react-tiff";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const { Option } = Select;
const PDFViewer = ({
  fileUrl,
  pageCategories,
  fileName,
  pagesResults,
  taskId,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [inputPage, setInputPage] = useState("");
  const [viewMode, setViewMode] = useState("all");
  const [currentPageCategory, setCurrentPageCategory] = useState("");
  const [currentPageServiceDate, setCurrentPageServiceDate] = useState(null);
  const [filteredPages, setFilteredPages] = useState([]);
  const [localPagesResults, setLocalPagesResults] = useState(pagesResults);

  const isImageFile = (fileName) => {
    const fileExtension = fileName.split(".").pop().toLowerCase();
    if (!fileExtension || fileExtension === "pdf") {
      return false;
    }
    const knownImageExtensions = ["jpg", "jpeg", "png", "tif", "gif"];
    if (knownImageExtensions.includes(fileExtension)) {
      return true;
    }
    const lowerCaseFileName = fileName.toLowerCase();
    return knownImageExtensions.some((ext) =>
      lowerCaseFileName.includes(`.${ext}`)
    );
  };

  useEffect(() => {
    if (isImageFile(fileName)) {
      getCurrentPageCategory(1);
    }
  }, [fileName]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
    setInputPage("1");
    setLoading(false);
    setFilteredPages(Array.from({ length: numPages }, (_, i) => i + 1));
  };

  const onDocumentLoadError = (error) => {
    setError(`Failed to load PDF file: ${error.message}`);
    setLoading(false);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
    setInputPage((previousPage) =>
      (parseInt(previousPage) + offset).toString()
    );
  };

  const previousPage = () => {
    changePage(-1);
    getCurrentPageCategory(parseInt(pageNumber) - 1);
  };

  const nextPage = () => {
    changePage(1);
    getCurrentPageCategory(parseInt(pageNumber) + 1);
  };

  const jumpToPage = (page) => {
    const pageNum = Number(page);
    if (pageNum >= 1 && pageNum <= numPages) {
      setPageNumber(pageNum);
    }
  };

  const handlePageChange = (e) => {
    setInputPage(e.target.value);
  };

  const handleJump = (e) => {
    e.preventDefault();
    jumpToPage(inputPage);
    getCurrentPageCategory(inputPage);
  };

  const goToFirstPage = () => {
    setPageNumber(1);
    setInputPage("1"); // Update the input box to reflect the page change
    getCurrentPageCategory(1);
  };

  const goToLastPage = () => {
    setPageNumber(numPages);
    setInputPage(numPages.toString()); // Update the input box to reflect the page change
    getCurrentPageCategory(numPages);
  };

  const handlePageCategoryChange = async (category) => {
    AuthenticatedApi.put(`/api/tasks/${taskId}/override-page-classification`, {
      classification: category,
      page_num: pageNumber.toString(),
      file_name: fileName,
    })
      .then((response) => {
        if (response.data.status === "success") {
          notification.success({
            message: "Page category updated successfully",
          });
          setCurrentPageCategory(category);
          const updatedPagesResults = { ...localPagesResults };
          const currentPageIndex = updatedPagesResults[fileName].findIndex(
            (page) => page.page_num === pageNumber - 1
          );
          if (currentPageIndex !== -1) {
            updatedPagesResults[fileName][currentPageIndex].classification =
              category;
          } else {
            updatedPagesResults[fileName].push({
              page_num: pageNumber - 1,
              classification: category,
            });
          }
          setLocalPagesResults(updatedPagesResults);
          const filtered = updatedPagesResults[fileName]
            .filter((page) => page.classification === category)
            .map((page) => page.page_num + 1);
          setFilteredPages(filtered);
        } else {
          notification.error({
            message: "Failed to update page category",
          });
        }
      })
      .catch((error) => {
        console.error("Error updating page category:", error);
        const errorMessage =
          error.response?.data?.message || "Unknown error during update";
        notification.error({
          message: `Failed to update page category: ${errorMessage}`,
        });
      });
  };

  const handlePageServiceDateChange = async (date) => {
    const serviceDate = date ? date.format("MM/DD/YYYY") : null;
    AuthenticatedApi.put(`/api/tasks/${taskId}/override-page-classification`, {
      service_date: serviceDate,
      page_num: pageNumber.toString(),
      file_name: fileName,
    })
      .then((response) => {
        if (response.data.status === "success") {
          notification.success({
            message: "Service date updated successfully",
          });
          setCurrentPageServiceDate(date);
          const updatedPagesResults = { ...localPagesResults };
          const currentPageIndex = updatedPagesResults[fileName].findIndex(
            (page) => page.page_num === pageNumber - 1
          );
          if (
            currentPageIndex !== -1 &&
            updatedPagesResults[fileName][currentPageIndex]?.fields?.e_signature
              ?.length > 0
          ) {
            updatedPagesResults[fileName][
              currentPageIndex
            ].fields.e_signature[0].service_date = serviceDate;
          } else {
            updatedPagesResults[fileName][currentPageIndex].fields = {
              e_signature: [{ service_date: serviceDate }],
            };
          }
          setLocalPagesResults(updatedPagesResults);
        } else {
          notification.error({
            message: "Failed to update service date",
          });
        }
      })
      .catch((error) => {
        console.error("Error updating service date:", error);
        const errorMessage =
          error.response?.data?.message || "Unknown error during update";
        notification.error({
          message: `Failed to update service date: ${errorMessage}`,
        });
      });
  };

  const toggleViewMode = () => {
    setViewMode(viewMode === "single" ? "all" : "single");
    if (viewMode === "all") {
      getCurrentPageCategory(1);
    }
  };

  const getCurrentPageCategory = (pNo) => {
    console.log("Page Results: ", localPagesResults);
    const currentDoc = localPagesResults[fileName];
    const currentPage = parseInt(pNo) - 1;
    setCurrentPageCategory(
      currentDoc?.find((page) => page.page_num === currentPage)?.classification
    );
    let serviceDate = null;
    try {
      serviceDate = currentDoc?.find((page) => page.page_num === currentPage)
        ?.fields?.e_signature[0]?.service_date;
    } catch (error) {
      console.log("Error: ", error);
    }

    if (serviceDate) {
      setCurrentPageServiceDate(dayjs(serviceDate, "MM/DD/YYYY"));
    } else {
      setCurrentPageServiceDate(null);
    }
  };

  if (isImageFile(fileName)) {
    return (
      <>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <DatePicker
            style={{ marginRight: "10px" }}
            value={currentPageServiceDate}
            onChange={handlePageServiceDateChange}
          />
          <Select
            value={currentPageCategory}
            style={{
              width: 200,
              marginRight: "10px",
              marginLeft: "10px",
            }}
            onChange={handlePageCategoryChange}
          >
            {pageCategories.map((category, index) => (
              <Option key={index} value={category}>
                {category}
              </Option>
            ))}
          </Select>
        </div>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          {fileName.includes(".tif") ? (
            <div
              style={{ maxWidth: "100%", maxHeight: "85vh", overflowY: "auto" }}
            >
              <TIFFViewer tiff={fileUrl} />
            </div>
          ) : (
            <img
              src={fileUrl}
              alt={fileName}
              style={{ maxWidth: "100%", maxHeight: "85vh" }}
            />
          )}
        </div>
      </>
    );
  }

  return (
    <div>
      {error ? (
        <Alert message={error} type="error" showIcon />
      ) : (
        <div>
          {loading && <Spin />}
          <div
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Button onClick={toggleViewMode}>
              {viewMode === "single"
                ? "Show All Pages"
                : "Show 1 Page at a time"}
            </Button>
            {viewMode === "single" && (
              <>
                <Button
                  icon={<DoubleLeftOutlined />}
                  onClick={goToFirstPage}
                  disabled={pageNumber <= 1}
                  style={{ margin: "0 8px" }}
                />
                <Button
                  icon={<LeftOutlined />}
                  onClick={previousPage}
                  disabled={pageNumber <= 1}
                  style={{ margin: "0 8px" }}
                />
                {numPages && (
                  <form
                    onSubmit={handleJump}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Input
                      value={inputPage}
                      onChange={handlePageChange}
                      onPressEnter={handleJump} // Handles enter key inside input
                      style={{
                        width: 50,
                        textAlign: "center",
                        marginRight: "10px",
                      }}
                      maxLength={numPages.toString().length}
                    />
                    <span> / {numPages}</span>
                  </form>
                )}
                <Button
                  icon={<RightOutlined />}
                  onClick={nextPage}
                  disabled={pageNumber >= numPages}
                  style={{ margin: "0 8px" }}
                />
                <Button
                  icon={<DoubleRightOutlined />}
                  onClick={goToLastPage}
                  disabled={pageNumber >= numPages}
                  style={{ margin: "0 8px" }}
                />
                <DatePicker
                  value={currentPageServiceDate}
                  style={{ marginRight: "10px" }}
                  onChange={handlePageServiceDateChange}
                />
                <Select
                  value={currentPageCategory}
                  style={{
                    width: 200,
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                  onChange={handlePageCategoryChange}
                >
                  {pageCategories.map((category, index) => (
                    <Option key={index} value={category}>
                      {category}
                    </Option>
                  ))}
                </Select>
              </>
            )}
          </div>
          <div style={{ overflowY: "auto", maxHeight: "85vh" }}>
            <Document
              file={fileUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
              renderMode="canvas"
            >
              {viewMode === "all" ? (
                Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={index}
                    pageNumber={index + 1}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    wrap={false}
                  />
                ))
              ) : (
                <Page
                  pageNumber={pageNumber}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  wrap={false}
                />
              )}
            </Document>
          </div>
        </div>
      )}
    </div>
  );
};

export default PDFViewer;
