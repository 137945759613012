import {
  Create,
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  FileInput,
  FileField,
  DateTimeInput,
  ReferenceField,
  DateField,
} from "react-admin";

export const ListPolicy = (props) => {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="content" />
        <ReferenceField source="payor_id" reference="payors" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="specialty_id"
          reference="specialties"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="coverage_id" reference="coverages" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="pdf_url" title="PDF Url" />
        <DateField source="validity_date" />
      </Datagrid>
    </List>
  );
};

export const CreatePolicy = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput multiline source="content" />
      <ReferenceInput source="payor_id" reference="payors" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="specialty_id" reference="specialties" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="coverage_id" reference="coverages" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <FileInput source="file" label="Policy PDF" accept="application/pdf">
        <FileField source="src" title="title" />
      </FileInput>
      <DateTimeInput source="validity_date" />
    </SimpleForm>
  </Create>
);

export const EditPolicy = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput multiline source="content" />
      <ReferenceInput source="payor_id" reference="payors" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="specialty_id" reference="specialties" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="coverage_id" reference="coverages" allowEmpty>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <FileInput source="file" label="Policy PDF" accept="application/pdf">
        <FileField source="src" title="title" />
      </FileInput>
      <DateTimeInput source="validity_date" />
    </SimpleForm>
  </Edit>
);
