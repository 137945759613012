import { createContext, useContext } from "react";
import useAuthRoleCheck from "../utils/UseAuthRoleCheck";

const AuthRoleContext = createContext();

export function useAuthRole() {
  return useContext(AuthRoleContext);
}

export const AuthRoleProvider = ({
  children,
  isAuthenticated,
  isLoading,
  loginWithRedirect,
  user,
}) => {
  const authRoleDetails = useAuthRoleCheck(
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    user
  );

  return (
    <AuthRoleContext.Provider value={authRoleDetails}>
      {children}
    </AuthRoleContext.Provider>
  );
};
