import {
  Create,
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  Show,
  ArrayInput,
  ArrayField,
  SimpleShowLayout,
  SimpleFormIterator,
} from "react-admin";

export const ListTaskType = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="tag" />
      <TextField source="name" />
    </Datagrid>
  </List>
);

export const CreateTaskType = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="tag" fullWidth />
      <TextInput source="name" fullWidth />
      <ArrayInput
        source="custom_fields"
        style={{ marginLeft: "20px" }}
        fullWidth
      >
        <SimpleFormIterator fullWidth>
          <TextInput source="field_name" label="Field Name" fullWidth />
          <TextInput source="example_field" label="Example Field" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);

export const EditTaskType = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="tag" fullWidth />
      <TextInput source="name" fullWidth />
      <ArrayInput
        source="custom_fields"
        style={{ marginLeft: "20px" }}
        fullWidth
      >
        <SimpleFormIterator fullWidth>
          <TextInput source="field_name" label="Field Name" fullWidth />
          <TextInput source="example_field" label="Example Field" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
);

export const ShowTaskType = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="tag" />
      <TextField source="name" />
      <ArrayField source="custom_fields">
        <Datagrid>
          <TextField source="field_name" label="Field Name" />
          <TextField source="example_field" label="Example Field" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
