import axios from "axios";

export const initApi = async () => {
  var conn = axios.create({ baseURL: window.location.origin });

  var config = null;

  try {
    var result = await conn.get("/api/config");
    config = result.data;
  } catch (error) {
    alert("Cannot get configuration. Contact Synthpop Support");
  }
  return config;
};

export const PreAuthApi = axios.create({
  baseURL: window.location.origin,
});

export const UnAuthenticatedApi = axios.create({
  baseURL: window.location.origin,
});
