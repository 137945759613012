/* eslint-disable no-inner-declarations */
import { useState, useEffect, useCallback, useRef } from "react";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";

import { useAuth0 } from "@auth0/auth0-react";
import {
  Col,
  Row,
  Button,
  Modal,
  Select,
  notification,
  Input,
  Switch,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useAuthRole } from "../context/AuthRoleContext";
import "../style.css";
import "./TasksList.css";
import { handleError, ingestTaskDetails } from "../utils/utilities";
import OutputModal from "./OutputModal";
import TaskSoapAvs from "./TaskSoapAvs";
import TaskClinicalNecessity from "./TaskClinicalNecessity";
import TaskVoiceAgent from "./TaskVoiceAgent";
import TaskReferralEntry from "./TaskReferralEntry";
import TaskGeneticTesting from "./TaskGeneticTesting";
import TaskHealthRecord from "./TaskHealthRecord";
import TaskPayorPolicyIngestion from "./TaskPayorPolicyIngestion";
import TaskGenericSleepTask from "./TaskGenericSleepTask";
import BugReportModal from "./BugReportModal";
import PaginatedCards from "./PaginatedCard";
import ExportTasksModal from "./ExportTasksModal";
import LoadingComponent from "./LoadingComponent";

const { Option } = Select; // Ant Design Select component

const TasksList = () => {
  const { user } = useAuth0();
  const {
    notFound,
    loaded,
    enabledTasks,
    name,
    userId,
    faxNumbers,
    orgId,
    switchedOrg,
    role,
  } = useAuthRole();
  const [waiting, setWaiting] = useState(true);
  const [openNewTaskModal, setOpenNewTaskModal] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [selectedData, setSelectedData] = useState(null); // [selectedData, setSelectedData
  const [taskType, setTaskType] = useState("");
  const [selectedName, setSelectedName] = useState(null);
  const [enabledSubmit, setEnabledSubmit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [singleTask, setSingleTask] = useState(false);
  const [singleUser, setSingleUser] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState([]);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [shouldUpdateTask, setShouldUpdateTask] = useState(null);
  const [pageSize, setPageSize] = useState(10); // Set this to whatever page size you're using by default
  const [filterInput, setFilterInput] = useState(null);
  const [selectedFilterInput, setSelectedFilterInput] = useState(null);
  const [selectedFilterField, setSelectedFilterField] = useState(null);
  const [openedPdf, setOpenedPdf] = useState(false);
  const openedPdfRef = useRef(openedPdf);
  const selectedDataRef = useRef(selectedData);
  const [sortConfig, setSortConfig] = useState({
    key: "id",
    direction: "desc",
  });
  const [showBlockedOnly, setShowBlockedOnly] = useState(false);
  const [availableTasks, setAvailableTasks] = useState([]);
  const [totalTasks, setTotalTasks] = useState(0);
  const [enabledTaskDict, setEnabledTaskDict] = useState({});
  const defaultPageOnFilter = 1;
  const sortOptions = [
    { value: "id", label: "Time" },
    { value: "name", label: "Task Name" },
    { value: "status", label: "Status" },
    { value: "user_account.name", label: "Creator" },
  ];

  const [sortOrderOptions, setSortOrderOptions] = useState([
    { value: "asc", label: "Ascending" },
    { value: "desc", label: "Descending" },
  ]);
  const [uniqueTaskStatus, setUniqueTaskStatus] = useState(null);
  const [uniqueCreators, setUniqueCreators] = useState(null);

  // Task details for each task type Default is TaskGenericSleepTask
  const taskDetails = {
    soap_avs: { component: TaskSoapAvs },
    soap_avs_upload: { component: TaskSoapAvs },
    soap_avs_live: { component: TaskSoapAvs },
    referral_entry: { component: TaskReferralEntry },
    clinical_necessity: { component: TaskClinicalNecessity },
    clinical_reasoning: { component: TaskClinicalNecessity },
    voice_agent: { component: TaskVoiceAgent },
    genetic_testing: { component: TaskGeneticTesting },
    fhir_json: { component: TaskReferralEntry },
    health_record: { component: TaskHealthRecord },
    insurance_card_record: { component: TaskHealthRecord },
    document_type: { component: TaskHealthRecord },
    payor_policy_ingestion: { component: TaskPayorPolicyIngestion },
  };

  const handleSave = async (event, key, dataIndex, value) => {
    console.log(value);
    event.stopPropagation();
    if (!value) {
      value = selectedName;
    }
    const newData = [...tasks];
    const index = tasks.findIndex((item) => key === item.id);

    if (index > -1) {
      const item = newData[index];
      item[dataIndex] = value;
      // Assuming you have a function or method to update tasks in your component
      // Update the tasks using your custom update function
      // updateTasks(newData); // Example function to update tasks
      try {
        AuthenticatedApi.put(`/api/tasks/${key}`, { [dataIndex]: value })
          .then(() => {
            let updatedTask = { ...newData[index], [dataIndex]: value };
            newData.splice(index, 1, updatedTask);
            console.log(index, key, dataIndex, updatedTask, newData);
            setTasks(newData);
            setSelectedName(null);
          })
          .catch((error) => {
            console.log(error.message);
            const errorMessage = handleError(error);
            notification.error({ message: errorMessage });
          });
      } catch {
        console.log("Error updating task");
      }
    } else {
      // Handle adding a new task if needed
    }
  };

  const handleCheckboxChange = async (event, record) => {
    event.stopPropagation();
    const newData = [...tasks];
    const index = tasks.findIndex((item) => record.id === item.id);
    if (index > -1) {
      const item = newData[index];
      item.cleared = event.target.checked;
      try {
        AuthenticatedApi.put(`/api/tasks/${record.id}/clear`, {
          cleared: event.target.checked,
        })
          .then(() => {
            let updatedTask = {
              ...newData[index],
              cleared: event.target.checked,
            };
            newData.splice(index, 1, updatedTask);
            console.log(
              index,
              record.id,
              event.target.checked,
              updatedTask,
              newData
            );
            setTasks(newData);
          })
          .catch((error) => {
            console.log(error.message);
            const errorMessage = handleError(error);
            notification.error({ message: errorMessage });
          });
      } catch {
        console.log("Error updating task");
      }
    }
  };

  const refreshResubmittedTask = async (taskId, skipClick = false) => {
    const selectedTask = tasks.find((task) => task.id === taskId);

    if (!selectedTask) {
      console.error("Task not found.");
      return;
    }

    try {
      await fetchTask(false, selectedTask);
      const mockEvent = {
        stopPropagation: () => {},
      };
      if (!skipClick) {
        setTimeout(() => handleRowClick(mockEvent, selectedTask), 1000);
      }
    } catch (error) {
      console.error("Error refreshing task:", error);
    }
  };

  const deleteTask = async (taskId) => {
    try {
      await AuthenticatedApi.delete(`/api/tasks/${taskId}/hard_delete`);
      notification.success({
        message: "Task deleted successfully",
      });
      await fetchTask(false);
    } catch (error) {
      console.error("Failed to delete task:", error);
      notification.error({
        message: "Failed to delete task",
      });
    }
  };

  const setHasUnsavedChanges = (dataId) => {
    setUnsavedChanges((prevUnsavedChanges) => {
      // Check if the dataId is not already in the array to avoid duplicates
      if (!prevUnsavedChanges.includes(dataId)) {
        return [...prevUnsavedChanges, dataId];
      }
      return prevUnsavedChanges;
    });
  };

  const removeUnsavedChange = (dataIdToRemove) => {
    setUnsavedChanges((prevUnsavedChanges) => {
      // Filter out the dataId to remove
      return prevUnsavedChanges.filter((dataId) => dataId !== dataIdToRemove);
    });
  };

  const handleRowClick = async (event, record) => {
    if (unsavedChanges.length > 0) {
      const saveButton = document.querySelector(
        `#save-updated-${unsavedChanges[0]}`
      );
      if (saveButton) {
        saveButton.click();
      }
      return;
    }
    event.stopPropagation();
    console.log(record);
    AuthenticatedApi.get(`/api/tasks/${record.id}`)
      .then((response) => {
        record = response.data;
        console.log("Updated Selected Row:", record);
        setSelectedData(ingestTaskDetails(record, response.data));
      })
      .catch((error) => {
        console.log(error.message);
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
      });
  };

  const showNewTaskModal = () => {
    setOpenNewTaskModal(true);
    setConfirmLoading(true);
    document.body.style.overflow = "hidden";
  };

  const clearForms = () => {
    setFileList([]);
    setEnabledSubmit(false);
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    document.body.style.overflow = "auto";
    setOpenNewTaskModal(false);
    setConfirmLoading(false);
    clearForms();
  };

  const handleSelectChange = (value) => {
    console.log(`selected ${value}`);
    setTaskType(value);
    clearForms();
  };

  const fetchActiveUserCount = useCallback(async () => {
    AuthenticatedApi.get("/api/organizations/active_operators_count")
      .then((response) => {
        if (response.data.active_operators <= 1) {
          setSingleUser(true);
        }
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
      });
  }, []);

  const fetchTaskTitle = (task_type) => {
    const taskTitle = enabledTaskDict[task_type];
    if (taskTitle) {
      return taskTitle;
    }
  };

  const fetchTask = useCallback(
    async (
      pending_only = false,
      alreadySelectedTask = null,
      org = null,
      page = currentPage,
      pageSize = 10,
      sortField = sortConfig.key,
      sortOrder = sortConfig.direction,
      blockedOnly = showBlockedOnly,
      filterInput = selectedFilterInput,
      filterField = selectedFilterField
    ) => {
      let params = {
        pending_only: pending_only,
        page: page,
        page_size: pageSize,
        sort_field: sortField,
        sort_order: ["asc", "desc"].includes(sortOrder) ? sortOrder : "desc",
        blocked_only: blockedOnly,
        filter_input: filterInput,
        filter_field: filterField,
      };
      if (org !== null) {
        params.org_id = org;
      }
      AuthenticatedApi.get("/api/tasks", { params: params })
        .then((response) => {
          if (pending_only) {
            // Do not update the state if the create task modal is open
            // because the TaskComponent gets re-rendered and the state gets reset
            console.log("Opened PDF:", openedPdfRef.current);
            if (openNewTaskModal || openedPdfRef.current) {
              return;
            }

            setTasks((prevTasks) => {
              const updatedTasks = prevTasks.map((task) => {
                // Find the updated task from the response data
                const updatedTask = response.data.tasks.find(
                  (respTask) => respTask.id === task.id
                );
                if (
                  updatedTask &&
                  !shouldUpdate &&
                  updatedTask.id ===
                    selectedDataRef?.current?.selectedRow?.id &&
                  (updatedTask.status !== task.status ||
                    updatedTask.percentage_completed !==
                      task.percentage_completed)
                ) {
                  setShouldUpdate(true);
                  setShouldUpdateTask(updatedTask);
                }
                // If an updated task is found, return it, otherwise return the original task
                return updatedTask || task;
              });
              // Find and add new tasks that were not already in the state
              const newTasks = response.data.tasks.filter(
                (task) => !prevTasks.some((prevTask) => prevTask.id === task.id)
              );
              const allTasks = [...updatedTasks, ...newTasks];
              const startIndex = page <= 1 ? 0 : allTasks.length - pageSize;
              const endIndex = startIndex + pageSize;
              return allTasks.slice(startIndex, endIndex);
            });
          } else {
            setTasks(response.data.tasks);
            setTotalTasks(response.data.total_count);
            if (response.data.unique_statuses) {
              setUniqueTaskStatus(response.data.unique_statuses);
            }
            if (response.data.unique_creators) {
              setUniqueCreators(response.data.unique_creators);
            }
            const mockEvent = {
              stopPropagation: () => {},
            };
            if (!alreadySelectedTask && response.data.tasks.length > 0) {
              handleRowClick(mockEvent, response.data.tasks[0]);
            } else if (alreadySelectedTask) {
              handleRowClick(mockEvent, alreadySelectedTask);
            }
          }
          setWaiting(false);
        })
        .catch((error) => {
          console.log(error);
          setWaiting(false);
          const errorMessage = handleError(error);
          notification.error({ message: errorMessage });
        });
      // eslint-disable-next-line
    },
    [openNewTaskModal, shouldUpdate]
  );

  useEffect(() => {
    openedPdfRef.current = openedPdf;
  }, [openedPdf]);

  useEffect(() => {
    selectedDataRef.current = selectedData;
  }, [selectedData]);

  useEffect(() => {
    if (user && loaded && enabledTasks) {
      let intervalId;
      const org = switchedOrg ? orgId : null;

      const fetchInterval = (isActive) => {
        const intervalTime = isActive ? 15000 : 30000;
        if (intervalId) clearInterval(intervalId);
        intervalId = setInterval(
          () => fetchTask(true, null, org),
          intervalTime
        );
      };

      function handleVisibilityChange() {
        if (document.hidden) {
          clearInterval(intervalId);
        } else {
          fetchInterval(true);
        }
      }

      function handleWindowFocus() {
        fetchInterval(true);
      }

      function handleWindowBlur() {
        fetchInterval(false);
      }

      fetchTask(false, null, org, currentPage, pageSize);
      fetchActiveUserCount();

      const haveSoapAvs = enabledTasks.find(
        (task) => task.task_key === "soap_avs"
      );
      const haveAddedTasks = enabledTasks.find(
        (task) => task.task_key === "soap_avs_upload"
      );

      if (!!haveSoapAvs && !haveAddedTasks) {
        // Create an array of new tasks to be added
        const extras = [
          {
            task_key: "soap_avs_upload",
            task_name: "SOAP Notes Upload",
            allowed_roles: haveSoapAvs.allowed_roles,
          },
          {
            task_key: "soap_avs_live",
            task_name: "Live Visit Notes",
            allowed_roles: haveSoapAvs.allowed_roles,
          },
        ];
        for (let x in extras) {
          enabledTasks.push(extras[x]);
        }
      }

      if (enabledTasks.length === 1) {
        setSingleTask(true);
      }

      if (enabledTasks.length >= 1) {
        setTaskType(enabledTasks[0].task_key);
      }

      // Set the availableTasks state with the updated enabledTasks
      setAvailableTasks(enabledTasks);
      let taskDict = {};
      enabledTasks.forEach((task) => {
        taskDict[task.task_key] = task.task_name;
      });
      setEnabledTaskDict(taskDict);

      // Start with the tab focus interval
      fetchInterval(true);

      // Add event listeners for tab focus/blur and visibility change
      window.addEventListener("focus", handleWindowFocus);
      window.addEventListener("blur", handleWindowBlur);
      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        clearInterval(intervalId);
        window.removeEventListener("focus", handleWindowFocus);
        window.removeEventListener("blur", handleWindowBlur);
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    }
    // No need to include 'tasks' here
    // console.log(tasks);
  }, [
    fetchTask,
    user,
    loaded,
    enabledTasks,
    fetchActiveUserCount,
    orgId,
    switchedOrg,
  ]);

  // useEffect to handle the current task status update
  useEffect(() => {
    // If there's a task update and it matches the selected task, then handle row click
    if (
      shouldUpdate &&
      shouldUpdateTask &&
      shouldUpdateTask.id === selectedData?.selectedRow?.id
    ) {
      if (shouldUpdateTask) {
        const mockEvent = {
          stopPropagation: () => {},
        };
        handleRowClick(mockEvent, shouldUpdateTask);
      }
      // Reset the flags after handling the row click
      setShouldUpdate(false);
      setShouldUpdateTask(null);
    }
    // Note: We are intentionally leaving out selectedData from the dependencies to prevent infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdate, shouldUpdateTask, tasks, handleRowClick]);

  const ModalContent = () => {
    const componentsToRender = [];
    if (!singleTask) {
      componentsToRender.push(
        <Select
          key="taskSelect"
          onChange={handleSelectChange}
          className="custom-width"
          defaultValue={taskType}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          {availableTasks.map(
            (task, index) =>
              task.allowed_roles.includes(role) && (
                <Option key={index} value={task.task_key}>
                  {task.task_name}
                </Option>
              )
          )}
        </Select>
      );
    }
    const TaskComponent =
      (taskDetails[taskType] && taskDetails[taskType].component) ||
      TaskGenericSleepTask;

    if (TaskComponent) {
      componentsToRender.push(
        <TaskComponent
          key="taskComponent"
          waiting={waiting}
          setWaiting={setWaiting}
          handleCancel={handleCancel}
          tasks={tasks}
          user={user}
          fileList={fileList}
          enabledSubmit={enabledSubmit}
          taskType={taskType}
          taskName={fetchTaskTitle(taskType)}
          setTasks={setTasks}
        />
      );
    }
    if (enabledTasks.length <= 0) {
      componentsToRender.push(
        <p key="noTasks" className="mt-2">
          You have not been assigned any tasks. Please contact your
          administrator.
        </p>
      );
    }
    return <>{componentsToRender}</>;
  };

  const handleTableChange = (currentPage, pagination) => {
    setCurrentPage(currentPage);
    setPageSize(pagination);
    fetchTask(
      false,
      null,
      null,
      currentPage,
      pagination,
      sortConfig.key,
      sortConfig.direction,
      showBlockedOnly,
      selectedFilterInput || filterInput,
      selectedFilterField
    );
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const handleSortFieldChange = (field) => {
    let newDirection = sortConfig.direction;

    const baseSortOptions = [
      { value: "asc", label: "Ascending" },
      { value: "desc", label: "Descending" },
    ];

    let sortOrderOptionsUpdate = baseSortOptions;

    if (field === "status") {
      const uniqueStatusValues = uniqueTaskStatus.map((value) => ({
        value,
        label: value,
      }));
      sortOrderOptionsUpdate = [...baseSortOptions, ...uniqueStatusValues];

      newDirection = "Completed";
    } else if (field === "user_account.name") {
      const uniqueCreatorValues = uniqueCreators.map((value) => ({
        value,
        label: value,
      }));
      sortOrderOptionsUpdate = uniqueCreatorValues;
      newDirection = name;
    } else {
      newDirection = ["asc", "desc"].includes(sortConfig.direction)
        ? sortConfig.direction
        : "desc";
    }
    setSortOrderOptions(sortOrderOptionsUpdate);
    setSortConfig((prevSortConfig) => ({
      ...prevSortConfig,
      key: field,
      direction: newDirection,
    }));
    setSelectedFilterInput(
      ["asc", "desc"].includes(newDirection) ? null : newDirection
    );
    setSelectedFilterField(
      ["asc", "desc"].includes(newDirection) ? null : field
    );

    fetchTask(
      false,
      null,
      orgId,
      currentPage,
      pageSize,
      field,
      sortConfig.direction,
      showBlockedOnly,
      ["asc", "desc"].includes(newDirection) ? null : newDirection,
      ["asc", "desc"].includes(newDirection) ? null : field
    );
  };

  const handleSortOrderChange = (order) => {
    const isStandardOrder = ["asc", "desc"].includes(order);
    setSortConfig((prevSortConfig) => ({
      ...prevSortConfig,
      direction: order,
    }));

    const sortOrder = isStandardOrder ? order : "desc";
    const filterInput = isStandardOrder ? null : order;
    const filterField = isStandardOrder ? null : sortConfig.key;

    setSelectedFilterInput(filterInput);
    setSelectedFilterField(filterField);

    fetchTask(
      false,
      null,
      orgId,
      currentPage,
      pageSize,
      sortConfig.key,
      sortOrder,
      showBlockedOnly,
      filterInput,
      filterField
    );
  };

  const handleBlockedOnlyChange = (checked) => {
    setShowBlockedOnly(checked);
    fetchTask(
      false,
      null,
      orgId,
      currentPage,
      pageSize,
      sortConfig.key,
      sortConfig.direction,
      checked,
      selectedFilterInput,
      selectedFilterField
    );
  };

  const handleFilterInputChange = (value) => {
    setFilterInput(value);
    setCurrentPage(defaultPageOnFilter);
    fetchTask(
      false,
      null,
      orgId,
      defaultPageOnFilter,
      pageSize,
      sortConfig.key,
      sortConfig.direction,
      showBlockedOnly,
      value
    );
  };

  return (
    <div>
      {!loaded && <LoadingComponent />}

      {notFound && (
        <div className="loading">
          The email you entered was not found in our database. Please contact
          elad@synthpop.ai to set up an account.
        </div>
      )}

      {loaded && !notFound && (
        <Row gutter={[48, 48]} className="p-4">
          <Col xs={24} sm={24} md={12} lg={9} xl={9}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {!switchedOrg && (
                <Button
                  visible={(!switchedOrg).toString()}
                  type="primary"
                  icon={<PlusOutlined />}
                  size="large"
                  className="no-border-radius-button"
                  onClick={showNewTaskModal}
                  title="Create New Task"
                >
                  New Task
                </Button>
              )}
              <ExportTasksModal />
              <BugReportModal
                userId={userId}
                userName={name}
                orgId={orgId}
                selectedRow={selectedData ? selectedData : null}
              />
            </div>
            <Modal
              title={singleTask ? "" : "Select Task"}
              open={openNewTaskModal} // "open" prop has been changed to "visible"
              confirmLoading={confirmLoading}
              onCancel={handleCancel}
              footer={null}
            >
              <ModalContent />
            </Modal>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "12px",
                  paddingBottom: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 2,
                    alignItems: "center",
                    flexWrap: "nowrap",
                  }}
                >
                  <span className="white-label" style={{ marginRight: "5px" }}>
                    Sort by:{" "}
                  </span>
                  <select
                    style={{ marginRight: "10px" }}
                    onChange={(e) => handleSortFieldChange(e.target.value)}
                  >
                    {sortOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <select
                    value={sortConfig.direction}
                    onChange={(e) => handleSortOrderChange(e.target.value)}
                  >
                    {sortOrderOptions?.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "10px",
                    }}
                  >
                    <span className="blue-label" style={{ marginRight: "5px" }}>
                      Blocked Only:
                    </span>
                    <Switch
                      checked={showBlockedOnly}
                      onChange={(checked) => handleBlockedOnlyChange(checked)}
                    />
                  </div>
                </div>
                <Input
                  style={{ width: "30%" }}
                  placeholder="Filter Tasks..."
                  value={filterInput}
                  onChange={(e) => handleFilterInputChange(e.target.value)}
                />
              </div>
              <PaginatedCards
                tasks={tasks}
                pageSize={pageSize}
                total={totalTasks}
                currentPage={currentPage}
                selectedData={selectedData}
                onPageChange={handleTableChange}
                onPageSizeChange={handlePageSizeChange}
                handleRowClick={handleRowClick}
                handleCheckboxChange={handleCheckboxChange}
                handleSave={handleSave}
                isSingleUser={singleUser}
                role={role}
                refreshResubmittedTask={refreshResubmittedTask}
                fetchTaskTitle={fetchTaskTitle}
                deleteTask={deleteTask}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={15} xl={15} className="pl-0">
            {tasks && selectedData && (
              <OutputModal
                role={role}
                faxNumbers={faxNumbers}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                tasks={tasks}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                activeKey={selectedData.currentPhase ? "3" : "1"} // Where do the magic numbers come from?
                pageSize={pageSize}
                unsavedChanges={unsavedChanges}
                setHasUnsavedChanges={setHasUnsavedChanges}
                removeUnsavedChange={removeUnsavedChange}
                refreshResubmittedTask={refreshResubmittedTask}
                setOpenedPdf={setOpenedPdf}
              />
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default TasksList;
