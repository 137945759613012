import { useState, useContext } from "react";
import { Form, Button, Typography, Upload, notification, Input } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import TimeAgo from "react-timeago";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../utils/utilities";
import ConfigContext from "../context/ConfigContext";

const { Title } = Typography;
const { TextArea } = Input;
const TaskSoapAvs = (props) => {
  const [fileList, setFileList] = useState(props.fileList || []);
  const [audioBlobs, setAudioBlobs] = useState([]);
  const [audioData, setAudioData] = useState([]);
  const [waiting] = useState(props.waiting);
  const [enabledSubmit, setEnabledSubmit] = useState(props.enabledSubmit);
  const [tasks] = useState(props.tasks);
  const taskType = props.taskType;
  const setTasks = props.setTasks;
  const handleCancel = props.handleCancel;
  const setWaiting = props.setWaiting;
  const recorderControls = useAudioRecorder();
  const config = useContext(ConfigContext);

  const handleRecordingComplete = (blob) => {
    const filename = "recorded_audio_" + new Date().getTime() + ".webm";
    const newBlob = { blob, filename };
    setAudioBlobs((prevBlobs) => [...prevBlobs, newBlob]);
    setAudioData((prevData) => [
      {
        blob,
        filename,
        recordedAt: new Date(), // Store the recording time
      },
      ...prevData,
    ]);
    setEnabledSubmit(true);
  };

  const handleUpload = ({ fileList }) => {
    setFileList(fileList);
    const allAudio = fileList.every((file) => file.type.startsWith("audio/"));
    setEnabledSubmit(
      allAudio && (fileList.length > 0 || audioBlobs.length > 0)
    );
  };

  const clear = () => {
    setFileList([]);
    setAudioBlobs([]);
    setEnabledSubmit(false);
    setWaiting(false);
    handleCancel();
  };

  const handleSubmit = async (values) => {
    setWaiting(true);
    let data_items = [
      {
        name: "Task Requirements",
        tag: "request-spec",
        data_type: "json",
        data: {},
      },
    ];

    let text_input = (values["relevant_patient_details"] || "").trim();
    if (text_input.length > 0) {
      data_items.push({
        name: "Patient Information",
        tag: "patient-info",
        data_type: "text",
        data: text_input,
      });
    }

    fileList.forEach((file, index) => {
      data_items.push({
        tag: "upload",
        data_type: "file",
        data: index,
        file_name: file.originFileObj.name,
      });
    });

    audioBlobs.forEach((audio, index) => {
      data_items.push({
        tag: "upload",
        data_type: "file",
        data: index,
        file_name: audio.filename,
      });
    });

    let createTask = {
      task_type: "soap_avs",
      data_items: data_items,
    };

    let formData = new FormData();
    formData.append("create_task", JSON.stringify(createTask));
    fileList.forEach((file) => {
      formData.append("uploads", file.originFileObj);
    });
    audioBlobs.forEach((audio) => {
      formData.append("uploads", audio.blob, audio.filename);
    });

    AuthenticatedApi.post("/api/v2/task/create", formData)
      .then((response) => {
        if (response.data) {
          setTasks([response.data, ...tasks]);
        }
        clear();
      })
      .catch((error) => {
        notification.error({ message: handleError(error) });
        clear();
      });
  };

  const detailsValue = `
      Patient
          First Name    :  
          Last Name     :  
          Date of Birth :  

      Visit
          Date          :  
          Stated Reason :  
      `;

  return (
    <div>
      <Title className="gpt-form-title">SOAP Notes & AVS</Title>
      <p>
        {taskType === "soap_avs"
          ? "Record or upload audio recordings of clinical visits."
          : taskType === "soap_avs_live"
            ? "Live recording, transcribing and summarizing of a patient visit."
            : taskType === "soap_avs_upload"
              ? "Upload and transcribe audio recordings of clinical visits."
              : ""}
      </p>
      <Form
        className="gpt-form-text"
        onFinish={handleSubmit}
        layout={"vertical"}
        initialValues={{ relevant_patient_details: detailsValue }}
      >
        {(taskType === "soap_avs" || taskType === "soap_avs_live") && (
          <Form.Item name="audio_record" label="">
            <AudioRecorder
              onRecordingComplete={handleRecordingComplete}
              recorderControls={recorderControls}
              downloadFileExtension="webm"
              showVisualizer={true}
              classes={{
                AudioRecorderPauseResumeClass: "custom-audio-recorder-options",
              }}
            />
            <div
              id="audio-sample"
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px;",
              }}
            >
              {audioData.map((audio, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "10px 0",
                  }}
                >
                  <audio src={URL.createObjectURL(audio.blob)} controls />
                  <span style={{ marginLeft: "10px" }}>
                    {audio.filename} Recorded{" "}
                    <TimeAgo date={audio.recordedAt} />
                  </span>
                </div>
              ))}
            </div>
          </Form.Item>
        )}
        {(taskType === "soap_avs" || taskType === "soap_avs_upload") && (
          <Form.Item name="file_upload" label="">
            <Upload
              beforeUpload={(file) => {
                const isAudio = file.type.startsWith("audio/");
                if (!isAudio) {
                  notification.error({
                    message: formatMessage(config.messages.tasks.invalid_file, {
                      file_list: "(.mp3, .wav, .webm)",
                    }),
                  });
                }
                return false;
              }}
              multiple={true}
              onChange={handleUpload}
              fileList={fileList}
            >
              <Button icon={<UploadOutlined />}>
                {taskType === "soap_avs" ? "Or" : ""} upload a visit recording
              </Button>
            </Upload>
          </Form.Item>
        )}
        <Form.Item
          name="relevant_patient_details"
          label="Optionally, fill in any important or missing patient details"
        >
          <TextArea className="gpt-form-text-area" rows={12} />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={waiting}
            disabled={!enabledSubmit}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TaskSoapAvs;
