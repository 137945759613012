import { useState, useContext } from "react";
import { Form, Input, Button, notification, Typography } from "antd";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import "./EditProfilePage.css";
import "../style.css";
import ConfigContext from "../context/ConfigContext";
import { handleError, formatMessage } from "../utils/utilities";

const { Title } = Typography;

const CreateOrganizationPage = () => {
  const [waiting, setWaiting] = useState(false);
  const config = useContext(ConfigContext);

  const onFinish = async (values) => {
    setWaiting(true);
    AuthenticatedApi.post("/create-organization", values)
      .then((response) => {
        setWaiting(false);
        if (response.status === 200) {
          notification.success({
            message: formatMessage(config.messages.organization.added),
          });
        } else {
          notification.error({
            message: formatMessage(config.messages.organization.creation_error),
          });
        }
      })
      .catch((error) => {
        setWaiting(false);
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
      });
  };

  return (
    <div>
      <div className="edit-profile-container">
        <Title>Create Organization</Title>
        <Form layout={"vertical"} name="editProfile" onFinish={onFinish}>
          <Form.Item
            label="Organization Name"
            name="org_name"
            rules={[
              {
                required: true,
                message: "Please input the organization name.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Admin's Name"
            name="admin_name"
            rules={[
              {
                required: true,
                message: "Please input the first admin's full name.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Admin's Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input the first admin's email.",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Secret Key"
            name="key"
            rules={[
              { required: true, message: "Please input the secret key." },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={waiting}>
              Create Organization
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CreateOrganizationPage;
