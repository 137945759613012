import React, { useState, useContext } from "react";
import {
  Form,
  Button,
  Typography,
  Upload,
  notification,
  Radio,
  Input,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../utils/utilities";
import ConfigContext from "../context/ConfigContext";

const { Title } = Typography;
const { TextArea } = Input;
const TaskClinicalNecessity = (props) => {
  const [fileList, setFileList] = useState(props.fileList);
  const [waiting] = useState(props.waiting);
  const [enabledSubmit, setEnabledSubmit] = useState(props.enabledSubmit);
  const [tasks] = useState(props.tasks);
  const setTasks = props.setTasks;
  const handleCancel = props.handleCancel;
  const setWaiting = props.setWaiting;
  const config = useContext(ConfigContext);

  const handleUpload = ({ fileList }) => {
    setFileList(fileList);
    setEnabledSubmit(fileList.length > 0);
  };

  const clear = () => {
    setFileList([]);
    setEnabledSubmit(false);
    setWaiting(false);
    handleCancel();
  };

  const handleSubmit = async (values) => {
    setWaiting(true);
    let data_items = [
      {
        name: "Task Requirements",
        tag: "request-spec",
        data_type: "json",
        data: {
          service_type: values["patient_service"],
          selection: values["payer_selection"],
          patient_details: values["relevant_patient_details"],
        },
      },
    ];

    fileList.forEach((file, index) => {
      data_items.push({
        tag: "upload",
        data_type: "file",
        data: index,
        file_name: file.originFileObj.name,
      });
    });

    let createTask = {
      task_type: "clinical_reasoning",
      data_items: data_items,
    };

    let formData = new FormData();
    formData.append("create_task", JSON.stringify(createTask));
    console.log(fileList);
    if (fileList.length > 0) {
      fileList.forEach((file) => {
        formData.append("uploads", file.originFileObj);
      });
    }
    console.log(formData);
    AuthenticatedApi.post("/api/v2/task/create", formData)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          setTasks([response.data, ...tasks]);
        }
        clear();
      })
      .catch((error) => {
        console.error("Error generating letter: ", error);
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
        clear();
      });
  };

  return (
    <>
      <Title className="gpt-form-title">Clinical Necessity Assessment</Title>
      <p>
        summarize patient record according to payor guidelines to preapprove
        treatment.
      </p>
      <Form
        className="gpt-form-text"
        onFinish={handleSubmit}
        layout={"vertical"}
        initialValues={{
          patient_service: `95810 - In-lab PSG study`,
          payer_selection: `UHC`,
          relevant_patient_details: `Name: Elad Ferber
MRN: 12345
DOB: Dec 02 1985
List of visit summaries by certified healthcare professionals in our clinic:
5/24/2023 Visit summary with Dr. Albright: Patient does not have counterindications for HSAT such as pulmonary or cardiac disease. Prescribed HSAT - WatchPAT device. 
5/02/2023 Visit summary with Dr. Albright: Patient complained about daytime sleepiness, High blood pressure 144/100 measured in clinic visit, BMI = 32, Obese
4/19/2023 Visit summary with Dr. Albright: Patient complains about snoring and gasping at night, ESS questionnaire shows score of 11. No recent changes to sleep schedule observed.
4/10/2023 Clinic intake form: Patient shows dark skin pigmentation and low perfusion which might impede HSAT performance. Patient has appropriate cognitive function to use the equipment at home.`,
        }}
      >
        <Form.Item
          name="patient_service"
          label="Patient Service"
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio
              style={{ display: "block" }}
              value="95810 - In-lab PSG study"
            >
              95810 - In-lab PSG study
            </Radio>
            <Radio
              style={{ display: "block" }}
              value="95800 - Home Sleep Apnea Testing"
            >
              95800 - Home Sleep Apnea Testing
            </Radio>
            <Radio style={{ display: "block" }} value="E0486 - OAT">
              E0486 - OAT
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="payer_selection"
          label="Payor Selection"
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio style={{ display: "block" }} value="UHC">
              UHC
            </Radio>
            <Radio style={{ display: "block" }} value="Humana">
              Humana
            </Radio>
            <Radio style={{ display: "block" }} value="CMS">
              CMS
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="relevant_patient_details"
          label="Relevant Patient Chart Summary"
          rules={[{ required: true }]}
        >
          <TextArea className="gpt-form-text-area" rows={12} />
        </Form.Item>
        <Form.Item name="file_upload">
          <Upload
            beforeUpload={(file) => {
              const isPdf =
                file.type === "application/pdf" || file.name.endsWith(".pdf");
              if (!isPdf) {
                notification.error({
                  message: formatMessage(config.messages.tasks.invalid_file, {
                    file_list: "PDF",
                  }),
                });
              }
              return isPdf;
            }}
            multiple={false}
            onChange={(info) => {
              if (info.fileList.length > 1) {
                notification.error({
                  message: formatMessage(config.messages.tasks.single_file_err),
                });
                return;
              }
              handleUpload(info);
            }}
            fileList={fileList}
            itemRender={(originNode) => {
              // Remove any tooltips
              return React.cloneElement(originNode, { title: "" });
            }}
          >
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={waiting}
            disabled={!enabledSubmit}
          >
            Review patient details according to payor guidelines
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default TaskClinicalNecessity;
