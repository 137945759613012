import { Modal } from "antd";

const ConfirmDeleteModal = ({ visible, onConfirm, onCancel }) => {
  return (
    <Modal
      title="Are you sure you want to delete this task?"
      open={visible}
      onOk={onConfirm}
      onCancel={onCancel}
      okText="Yes, delete it"
      cancelText="No, keep it"
      okType="danger"
    >
      <p>This action cannot be undone.</p>
    </Modal>
  );
};

export default ConfirmDeleteModal;
