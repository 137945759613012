import { createContext, useContext, useState, useMemo } from "react";
import { theme } from "antd";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const storedThemePreference = localStorage.getItem("isDarkMode");
    return storedThemePreference ? JSON.parse(storedThemePreference) : false;
  });

  const toggleTheme = () => {
    const newIsDarkMode = !isDarkMode;
    localStorage.setItem("isDarkMode", JSON.stringify(newIsDarkMode));
    setIsDarkMode(newIsDarkMode);
  };

  // Memoize the Ant Design theme configuration to prevent unnecessary recalculations
  const antdTheme = useMemo(
    () => ({
      algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
    }),
    [isDarkMode]
  );

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme, antdTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
