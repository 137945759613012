// WaitList.js
import { useState, useEffect, useContext } from "react";
import { Form, Input, Button, notification } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { formatMessage } from "../utils/utilities";
import ConfigContext from "../context/ConfigContext";

const WaitList = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const config = useContext(ConfigContext);

  useEffect(() => {
    if (isAuthenticated && !isLoading && user) {
      setContactName(user.name);
      setContactEmail(user.email);
    }
    console.log("user: ", user);
  }, [isAuthenticated, isLoading, user]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      AuthenticatedApi.post("/api/add_waitlist", values)
        .then((response) => {
          if (response.ok) {
            notification.success({
              message: formatMessage(
                config.messages.user.waitlist_request_success
              ),
            });
            form.resetFields();
          } else {
            notification.error({
              message: "Error sending request: " + response.statusText,
            });
          }
        })
        .catch((error) => {
          notification.error({
            message: "Error sending request: " + error.message,
          });
        });
    } catch (error) {
      notification.error({
        message: "Error sending request: " + error.message,
      });
    }
    setLoading(false);
  };

  return (
    <div className="edit-profile-container">
      <h2>Join Our Wait List</h2>
      <p>
        At the moment we are only accepting new organizations through our sales
        team. We are growing our clientele within the United States, and
        restricting ourselves to a small group of clinical specialties. Please
        tell us about your practice and how we can streamline your workflow
        painpoints, and we will get back to you through your contact email.
      </p>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="contact_name" label="Contact Name">
          <Input value={contactName} />
        </Form.Item>
        <Form.Item name="contact_email" label="Contact Email">
          <Input value={contactEmail} />
        </Form.Item>
        <Form.Item
          name="org_name"
          label="Organization Name"
          rules={[{ required: true, message: "Enter your organization name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="waitlist_message"
          label="Tell us a little about your clinic and operational workflow painpoints"
          rules={[{ required: true, message: "Enter a message" }]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Contact Sales Team
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default WaitList;
