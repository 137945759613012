/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, Children } from "react";
import { Table, Select, Typography, Tabs } from "antd";
import "./OrganizationPage.css";
import "../style.css";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { useAuthRole } from "../context/AuthRoleContext";
import ApiKeysTable from "./ApiKeysTable";
import LoadingComponent from "./LoadingComponent";
import Settings from "./organization_tabs/Settings";
import ConfigureFieldExtractor from "./organization_tabs/ConfigureFieldExtractor";
import CustomFieldEditor from "./organization_tabs/CustomFieldEditor";
import InsuranceBenefitAutomation from "./organization_tabs/InsuranceBenefitAutomation";
import UploadTaskFolder from "./organization_tabs/UploadTaskFolder";
import PayorPolicy from "./organization_tabs/PayorPolicy";
import AddUser from "./organization_tabs/AddUser";

const { Title } = Typography;

function OrganizationPage() {
  const payorSpecialtyFormRef = useRef(null);
  var [data, setData] = useState([]);
  const [waitingPolicy, setWaitingPolicy] = useState(false);
  const [isAdmin, setIsAdmin] = useState(null);
  const { enabledTasks, role, loaded } = useAuthRole();
  const [notFound, setNotFound] = useState(false);
  const [checked, setChecked] = useState(false);
  const [customFieldsInitValues, setCustomFieldsInitValues] = useState([
    { field_name: "", example_field: "" },
  ]);
  const [config, setConfig] = useState(null);
  const [availableTasks, setAvailableTasks] = useState([]);
  const [showExtApiCallbackToggle, setShowExtApiCallbackToggle] =
    useState(false);
  const [isExternalApiCallbackEnabled, setIsExternalApiCallbackEnabled] =
    useState(false);
  const [isVoiceAgentAutoOnly, setIsVoiceAgentAutoOnly] = useState(false);
  const [isMarkEmailsAsRead, setIsMarkEmailsAsRead] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      if (loaded) {
        const haveSoapAvs = enabledTasks.find(
          (task) => task.task_key === "soap_avs"
        );
        const haveAddedTasks = enabledTasks.find(
          (task) => task.task_key === "soap_avs_upload"
        );

        if (!!haveSoapAvs && !haveAddedTasks) {
          // Create an array of new tasks to be added
          const extras = [
            {
              task_key: "soap_avs_upload",
              task_name: "SOAP Notes Upload",
              allowed_roles: haveSoapAvs.allowed_roles,
            },
            {
              task_key: "soap_avs_live",
              task_name: "Live Visit Notes",
              allowed_roles: haveSoapAvs.allowed_roles,
            },
          ];
          for (let x in extras) {
            enabledTasks.push(extras[x]);
          }
        }
        // Set the availableTasks state with the updated enabledTasks
        setAvailableTasks(enabledTasks);
        if (loaded && !checked) {
          const response = await AuthenticatedApi.get("/api/organization");

          if (response.data.name === "Betternight") {
            setIsVoiceAgentAutoOnly(true);
          }

          if (response.data.role === "not found") {
            setNotFound(true);
          } else if (response.data["data"] === "not admin") {
            setIsAdmin(false);
          } else {
            var users = response.data["users"];
            setData(users);
            setIsAdmin(response.data.role.includes("admin"));
          }

          const customFieldsResponse = await AuthenticatedApi.get(
            "/api/get-custom-fields"
          );
          const customFields = customFieldsResponse.data.custom_fields;
          const fieldExtractionConfig =
            customFieldsResponse.data.field_extraction_config;
          if (customFields) {
            console.log("customFields", customFields);
            setCustomFieldsInitValues(customFields);
          }
          if (fieldExtractionConfig) {
            console.log("fieldExtractionConfig", fieldExtractionConfig);
            setConfig(fieldExtractionConfig);
          }
          setShowExtApiCallbackToggle(
            customFieldsResponse.data.api_config_exists
          );
          setIsExternalApiCallbackEnabled(customFieldsResponse.data.callback);
          setIsMarkEmailsAsRead(customFieldsResponse.data.mark_emails_as_read);

          setChecked(true);
        }
      }
    };
    checkAuth();
  }, [role, loaded, checked]);

  const columns = [
    {
      title: "User",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
    },
  ];

  const tabsItems = [
    {
      label: "Add User",
      key: "1",
      children: <AddUser setData={setData} />,
    },
    {
      label: "Add Payor Policy",
      key: "2",
      children: (
        <PayorPolicy
          payorSpecialtyFormRef={payorSpecialtyFormRef}
          waitingPolicy={waitingPolicy}
          setWaitingPolicy={setWaitingPolicy}
        />
      ),
    },
    {
      label: isVoiceAgentAutoOnly
        ? "Insurance Benefits Automation"
        : "Upload Tasks Folder",
      key: "3",
      children: (
        <>
          {!isVoiceAgentAutoOnly && (
            <UploadTaskFolder
              availableTasks={availableTasks}
              role={role}
              payorSpecialtyFormRef={payorSpecialtyFormRef}
            />
          )}
          {isVoiceAgentAutoOnly && <InsuranceBenefitAutomation />}
        </>
      ),
    },
    {
      label: "Custom Fields",
      key: "4",
      children: (
        <CustomFieldEditor
          customFieldsInitValues={customFieldsInitValues}
          setWaitingPolicy={setWaitingPolicy}
        />
      ),
    },
    {
      label: "Configure Field Extractor",
      key: "5",
      children: (
        <ConfigureFieldExtractor config={config} setConfig={setConfig} />
      ),
    },
    {
      label: "API Credentials",
      key: "6",
      children: (
        <div className="api-key-container">
          <Title className="edit-profile-title">Generate API Keys</Title>
          <ApiKeysTable />
        </div>
      ),
    },
    {
      label: "Settings",
      key: "7",
      children: (
        <Settings
          showExtApiCallbackToggle={showExtApiCallbackToggle}
          isExternalApiCallbackEnabled={isExternalApiCallbackEnabled}
          setIsExternalApiCallbackEnabled={setIsExternalApiCallbackEnabled}
          isMarkEmailsAsRead={isMarkEmailsAsRead}
          setIsMarkEmailsAsRead={setIsMarkEmailsAsRead}
        />
      ),
    },
  ];

  return (
    <div>
      {!loaded && <LoadingComponent />}
      {notFound && checked && (
        <div className="loading">
          The email you entered was not found in our database. Please contact
          elad@synthpop.ai to set up an account.
        </div>
      )}
      {!isAdmin && checked && (
        <div className="loading">
          You do not have permission to access this page.
        </div>
      )}

      {loaded && !notFound && checked && isAdmin && (
        <div>
          <div className="org-container">
            <Table dataSource={data} columns={columns} className="org-table" />
          </div>
          <Tabs defaultActiveKey="1" centered items={tabsItems} />
        </div>
      )}
    </div>
  );
}

export default OrganizationPage;
