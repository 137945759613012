import { useState, useEffect, useContext } from "react";
import { Modal, Button, Row, notification, Input, Tooltip, Select } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  CloseOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  ShareAltOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import {
  handleError,
  ingestTaskDetails,
  formatMessage,
} from "../utils/utilities";
import "./OutputModal.css";
import TaskDetails from "./TaskDetails";
import ConfigContext from "../context/ConfigContext";

const mobileBreakpoint = 768; // Width threshold for mobile devices
const { Option } = Select;
const OutputModal = (props) => {
  const [activeKey, setActiveKey] = useState(props.activeKey);
  const tasks = props.tasks;
  const [displayPoppedIn, setDisplayPoppedIn] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingMap, setLoadingMap] = useState({});
  const currentPage = props.currentPage;
  const setCurrentPage = props.setCurrentPage;
  const pageSize = props.pageSize;
  const selectedData = props.selectedData;
  const setSelectedData = props.setSelectedData;
  const unsavedChanges = props.unsavedChanges;
  const setHasUnsavedChanges = props.setHasUnsavedChanges;
  const removeUnsavedChange = props.removeUnsavedChange;
  const refreshResubmittedTask = props.refreshResubmittedTask;
  const faxNumbers = props.faxNumbers;
  const setOpenedPdf = props.setOpenedPdf;
  const role = props.role;
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < mobileBreakpoint
  );
  const config = useContext(ConfigContext);
  const documentCategories = config.document_categories;

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < mobileBreakpoint);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) {
      setDisplayPoppedIn(false);
    }
  }, [isMobile]);

  const handleCloseModal = () => {
    setSelectedData(null);
  };

  const fetchDataAndUpdate = async (task) => {
    AuthenticatedApi.get(`/api/tasks/${task.id}`)
      .then((response) => {
        setSelectedData(ingestTaskDetails(task, response.data));

        const index = tasks.findIndex((item) => task.id === item.id);
        const pageIndex = Math.ceil((index + 1) / pageSize);
        if (pageIndex !== currentPage) {
          setCurrentPage(pageIndex);
        }
      })
      .catch((error) => {
        console.error("Error fetching additional data:", error);
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
        setSelectedData({
          selectedRow: task,
          additionalData: "",
          additionalDataInput: "",
        });
      });
  };

  const onNext = async () => {
    const currentIndex = tasks.findIndex(
      (item) => selectedData.selectedRow.id === item.id
    );
    if (unsavedChanges.length > 0) {
      const saveButton = document.querySelector(
        `#save-updated-${unsavedChanges[0]}`
      );
      if (saveButton) {
        saveButton.click();
      }
      return;
    }
    if (currentIndex === 0) return;
    const prevTask = tasks[currentIndex - 1];
    await fetchDataAndUpdate(prevTask);
  };

  const onPrev = async () => {
    const currentIndex = tasks.findIndex(
      (item) => selectedData.selectedRow.id === item.id
    );
    if (unsavedChanges.length > 0) {
      const saveButton = document.querySelector(
        `#save-updated-${unsavedChanges[0]}`
      );
      if (saveButton) {
        saveButton.click();
      }
      return;
    }
    if (currentIndex === tasks.length - 1) return;
    const nextTask = tasks[currentIndex + 1];
    await fetchDataAndUpdate(nextTask);
  };

  const handleTogglePoppedIn = () => {
    setDisplayPoppedIn(!displayPoppedIn);
  };

  useEffect(() => {
    setSelectedData(props.selectedData);
    let activeKey = "3";
    setActiveKey(activeKey);
  }, [setSelectedData, props.selectedData]);

  const TitleIcon = ({ status }) => {
    const statusIconMap = {
      Completed: (
        <CheckCircleOutlined
          style={{
            color: "#52c41a",
            marginRight: "10px",
            fontSize: "15px",
            fontWeight: "bolder",
          }}
        />
      ),
      Failed: (
        <CloseCircleOutlined
          style={{
            color: "red",
            marginRight: "10px",
            fontSize: "15px",
            fontWeight: "bolder",
          }}
        />
      ),
      Blocked: (
        <ExclamationCircleOutlined
          style={{
            color: "orange",
            marginRight: "10px",
            fontSize: "15px",
            fontWeight: "bolder",
          }}
        />
      ),
    };

    return statusIconMap[status] || null;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleShare = () => {
    showModal();
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getShareableLink = () => {
    return selectedData ? `${window.location.href}/${selectedData.uuid}` : "";
  };

  const copyText = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log("Link copied to clipboard");
        notification.success({
          message: formatMessage(config.messages.copy.copy),
        });
      },
      (err) => {
        console.error("Failed to copy text: ", err);
      }
    );
  };

  const handleCategoryChange = async (newCategory) => {
    console.log("Selected:", newCategory);

    // Use AuthenticatedApi similar to fetchDataAndUpdate function
    AuthenticatedApi.put(
      `/api/tasks/${selectedData.selectedRow.id}/override-document-category`,
      {
        category: newCategory,
      }
    )
      .then((response) => {
        if (response.data.status === "success") {
          notification.success({
            message: formatMessage(config.messages.tasks.category_updated),
          });
          // Update local state with the new category
          setSelectedData({
            ...selectedData,
            documentCategory: { data: newCategory },
          });
          refreshResubmittedTask(selectedData.selectedRow.id, false);
        } else {
          notification.error({
            message: formatMessage(config.messages.tasks.category_updated, {
              error: "Server failure",
            }),
          });
        }
      })
      .catch((error) => {
        console.error("Error updating document category:", error);
        const errorMessage =
          error.response?.data?.message || "Unknown error during update";
        notification.error({
          message: formatMessage(config.messages.tasks.category_updated, {
            error: errorMessage,
          }),
        });
      });
  };

  const handleRerunAsApi = async (rowId) => {
    setLoadingMap((prevMap) => ({
      ...prevMap,
      [rowId]: true,
    }));
    try {
      await AuthenticatedApi.get(`/api/tasks/${rowId}/rerun-as-api`);
      notification.success({
        message: "Task re-run as API successfully",
      });
      setLoadingMap((prevMap) => ({
        ...prevMap,
        [rowId]: false,
      }));
    } catch (error) {
      console.error("Error re-running task as API:", error);
      setLoadingMap((prevMap) => ({
        ...prevMap,
        [rowId]: false,
      }));
      const errorMessage =
        error.response?.data?.message || "Unknown error during API re-run";
      notification.error({
        message: `Failed to re-run task as API: ${errorMessage}`,
      });
    }
  };

  const titleBar = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div>
        <Button
          icon={<LeftOutlined />}
          onClick={onPrev}
          style={{ marginRight: "10px" }}
          title="Previous Task"
        ></Button>
        <Button
          icon={<RightOutlined />}
          onClick={onNext}
          style={{ marginRight: "10px" }}
          title="Next Task"
        ></Button>
        {!isMobile && (
          <Button
            icon={
              !displayPoppedIn ? (
                <FullscreenExitOutlined />
              ) : (
                <FullscreenOutlined />
              )
            }
            onClick={handleTogglePoppedIn}
            style={{ marginRight: "10px" }}
            title="Toggle Popped In"
          ></Button>
        )}
        {selectedData && selectedData.selectedRow && (
          <TitleIcon status={selectedData.selectedRow.status} />
        )}
        <span
          id={selectedData?.documentCategory?.data}
          style={{ fontSize: "18px" }}
        >
          {selectedData && selectedData.selectedRow
            ? selectedData.selectedRow.name
            : "Results "}
        </span>
        <span style={{ marginLeft: "20px" }}>
          <Button
            icon={<ShareAltOutlined />}
            onClick={handleShare}
            title="Share Task URL"
          ></Button>
          <Modal
            title="Share Task"
            open={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <p>Copy the link below to share this task:</p>
            <Input
              addonAfter={
                <Tooltip title="Copy to clipboard">
                  <CopyOutlined
                    onClick={() => copyText(getShareableLink())}
                    style={{ cursor: "pointer" }}
                  />
                </Tooltip>
              }
              readOnly
              value={getShareableLink()}
            />
          </Modal>
        </span>
        <Select
          value={selectedData?.documentCategory?.data}
          style={{ width: 200, marginRight: "10px", marginLeft: "10px" }}
          onChange={handleCategoryChange}
        >
          {documentCategories.map((category, index) => (
            <Option key={index} value={category}>
              {category}
            </Option>
          ))}
        </Select>
        {selectedData && selectedData.selectedRow && (
          <Button
            type="primary"
            onClick={() => handleRerunAsApi(selectedData.selectedRow.id)}
            style={{ marginLeft: "10px" }}
            loading={loadingMap[selectedData.selectedRow.id]}
            disabled={loadingMap[selectedData.selectedRow.id]}
          >
            Re-run as API
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <>
      {displayPoppedIn ? (
        <div>
          {selectedData && selectedData.selectedRow && (
            <div>
              <Row>{titleBar}</Row>
              <Row>
                <TaskDetails
                  selectedData={selectedData}
                  role={role}
                  faxNumbers={faxNumbers}
                  unsavedChanges={unsavedChanges}
                  setHasUnsavedChanges={setHasUnsavedChanges}
                  removeUnsavedChange={removeUnsavedChange}
                  refreshResubmittedTask={refreshResubmittedTask}
                  activeKey={activeKey}
                  setActiveKey={setActiveKey}
                  setOpenedPdf={setOpenedPdf}
                />
              </Row>
            </div>
          )}
        </div>
      ) : (
        <Modal
          closeIcon={isMobile ? <CloseOutlined /> : <></>} // Hide default close button
          title={titleBar}
          maskClosable={false}
          open={
            selectedData.selectedRow !== null &&
            selectedData.additionalData !== null
          }
          onCancel={handleCloseModal}
          footer={null}
        >
          <TaskDetails
            selectedData={selectedData}
            role={role}
            faxNumbers={faxNumbers}
            unsavedChanges={unsavedChanges}
            setHasUnsavedChanges={setHasUnsavedChanges}
            removeUnsavedChange={removeUnsavedChange}
            refreshResubmittedTask={refreshResubmittedTask}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            setOpenedPdf={setOpenedPdf}
          />
        </Modal>
      )}
    </>
  );
};

export default OutputModal;
