import { useContext } from "react";
import { PreAuthApi } from "./MyApi";
import { useAuth0 } from "@auth0/auth0-react";
import { notification } from "antd";
import { formatMessage } from "./utilities";
import ConfigContext from "../context/ConfigContext";

const AuthenticatedApi = PreAuthApi;

const AuthenticatedApiProvider = ({ children }) => {
  const { user, getAccessTokenSilently, logout } = useAuth0();
  const appConfig = useContext(ConfigContext);

  AuthenticatedApi.interceptors.request.use(async (config) => {
    const accessToken = await getAccessTokenSilently();
    if (user) {
      config.headers["UID"] = user.email;
    }
    config.headers["Authorization"] = `Bearer ${accessToken}`;
    return config;
  });

  AuthenticatedApi.interceptors.response.use(
    (response) => {
      // Any status code that lies within the range of 2xx will make this function trigger
      return response;
    },
    (error) => {
      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data.detail === "User is inactive"
      ) {
        notification.error({
          message: formatMessage(appConfig.messages.user.account_disabled),
        });
        setTimeout(() => {
          logout({
            logoutParams: { returnTo: window.location.origin },
          });
        }, 2000);
      }
      // Any status codes that falls outside the range of 2xx will make this function trigger
      window.dispatchEvent(new CustomEvent("axiosError", { detail: error }));
      return Promise.reject(error);
    }
  );

  return <>{children}</>;
};

export { AuthenticatedApi, AuthenticatedApiProvider };
