import { useState } from "react";
import { Modal, Button, Input, Checkbox, Tooltip, notification } from "antd";
import { WarningOutlined, CheckCircleOutlined } from "@ant-design/icons";
const TaskIssues = ({
  taskId,
  issues,
  onIssueOverride,
  setIsSubmitDisabled,
  setRelevantDetails,
}) => {
  const [visible, setVisible] = useState(false);
  const [currentIssues, setCurrentIssues] = useState([]);
  const [inputText, setInputText] = useState("");
  const [checked, setChecked] = useState(false);
  const [checkboxToggled, setCheckboxToggled] = useState(false);
  const [detailsByIssue, setDetailsByIssue] = useState({});

  const groupIssues = issues.reduce((acc, issue) => {
    const prefix = "dme_referral_issues";
    if (issue.key.startsWith(prefix)) {
      acc[prefix] = acc[prefix] || [];
      acc[prefix].push(issue);
    } else {
      acc.others = acc.others || [];
      acc.others.push(issue);
    }
    return acc;
  }, {});

  const showModal = (issueGroup) => {
    setCurrentIssues(issueGroup);
    setVisible(true);
    setInputText(issueGroup[0]?.comment || "");
    setChecked(issueGroup.every((issue) => issue.override));
    setCheckboxToggled(false);
  };

  const handleOk = async (action) => {
    let overrideReason = "";
    if (action === "override" && checkboxToggled && checked) {
      overrideReason = inputText;
    } else if (action === "details") {
      overrideReason = "Relevant Details";
      aggregateAndSetDetails(currentIssues[0]?.key, inputText); // Call to the aggregation function
    }
    try {
      currentIssues.forEach((issue) => {
        issue.override = checked;
        issue.comment = overrideReason;
        onIssueOverride({
          key: issue.key,
          override: checked,
          comment: overrideReason,
        });
      });
      setIsSubmitDisabled(false);
    } catch (error) {
      notification.error({
        message: "Failed to update issue override.",
      });
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const aggregateAndSetDetails = (issueKey, value) => {
    const updatedDetails = {
      ...detailsByIssue,
      [issueKey]: (detailsByIssue[issueKey] || "") + "\n" + value.trim(), // Append new details to existing
    };
    setDetailsByIssue(updatedDetails);
    setRelevantDetails(Object.values(updatedDetails).join("\n")); // Convert all details into a single string for external use
  };

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
    setCheckboxToggled(true);
  };

  return (
    <>
      {Object.entries(groupIssues).map(([groupKey, issues], index) => (
        <div key={index}>
          {groupKey === "dme_referral_issues" ? (
            <div>
              <ul style={{ listStyleType: "none" }}>
                <li>
                  {issues.every((issue) => issue.override) ? (
                    <Tooltip title="All issues overridden">
                      <CheckCircleOutlined
                        style={{
                          color: "green",
                          fontSize: "18px",
                          marginRight: "10px",
                        }}
                        onClick={() => showModal(issues)}
                      />
                    </Tooltip>
                  ) : (
                    <WarningOutlined
                      onClick={() => showModal(issues)}
                      style={{
                        color: "red",
                        fontSize: "18px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                  Upload files to fulfill below requirements or override them
                  with an explanation
                </li>
              </ul>
            </div>
          ) : null}
          <ul
            style={{
              listStyleType: "none",
              marginLeft: groupKey === "dme_referral_issues" ? "20px" : "0",
              marginTop: groupKey === "dme_referral_issues" ? "0" : "10px",
            }}
          >
            {issues.map((issue) => (
              <li key={`${taskId}:${issue.key}`}>
                {issue.override ? (
                  <Tooltip title={issue.comment}>
                    <CheckCircleOutlined
                      style={{
                        color: "green",
                        fontSize: "18px",
                        marginRight: "10px",
                      }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title="Click to override">
                    <WarningOutlined
                      onClick={() => showModal([issue])}
                      style={{
                        color: "red",
                        fontSize: "18px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                )}
                {issue.message}
              </li>
            ))}
          </ul>
        </div>
      ))}

      <Modal
        title="Manage Issue"
        open={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submitOverride"
            type="primary"
            onClick={() => handleOk("override")}
            disabled={!checkboxToggled || !checked || !inputText}
          >
            Override Issue
          </Button>,
          <Button
            key="submitDetails"
            type="primary"
            onClick={() => handleOk("details")}
            disabled={!checkboxToggled || !checked || !inputText}
          >
            Add Relevant Details
          </Button>,
        ]}
      >
        <p>
          Please enter the reason for overriding this issue or provide relevant
          details:
        </p>
        <Input.TextArea value={inputText} onChange={handleInputChange} />
        <Checkbox checked={checked} onChange={handleCheckboxChange}>
          Confirm override
        </Checkbox>
      </Modal>
    </>
  );
};

export default TaskIssues;
