import { Create, Edit, SimpleForm, TextInput } from "react-admin";

export const CreatePcode = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="code_name" />
      <TextInput source="description" />
    </SimpleForm>
  </Create>
);

export const EditPcode = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="code_name" />
      <TextInput source="description" />
    </SimpleForm>
  </Edit>
);
