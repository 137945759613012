import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const useAutoLogout = (isAuthenticated, inactivityTime) => {
  const { logout } = useAuth0();

  useEffect(() => {
    let timer;

    const resetTimer = () => {
      clearTimeout(timer);
      if (isAuthenticated) {
        timer = setTimeout(() => {
          localStorage.removeItem("authState");
          logout({ logoutParams: { returnTo: window.location.origin } });
        }, inactivityTime);
      }
    };

    if (isAuthenticated) {
      window.addEventListener("mousemove", resetTimer);
      window.addEventListener("keydown", resetTimer);
      resetTimer();
    } else {
      clearTimeout(timer);
    }

    return () => {
      clearTimeout(timer);
      window.removeEventListener("mousemove", resetTimer);
      window.removeEventListener("keydown", resetTimer);
    };
  }, [isAuthenticated, logout, inactivityTime]);
};

export default useAutoLogout;
