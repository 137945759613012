import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthenticatedApi } from "./AuthenticatedApi";

const STORAGE_KEY = "authState";

function useAuthRoleCheck(isAuthenticated, isLoading, loginWithRedirect, user) {
  const [state, setState] = useState({
    checked: false,
    isAdmin: false,
    isSuperAdmin: false,
    isGroupAdmin: false,
    notFound: false,
    loaded: false,
    role: "",
    name: "",
    email: "",
    userId: "",
    enabledTasks: [],
    orgId: null,
    switchedOrg: false,
    faxNumbers: [],
    lastUpdatedAt: "",
    orgName: "",
  });
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const navigate = useNavigate();

  const checkWaitListStatus = async () => {
    try {
      const response = await AuthenticatedApi.get("/api/waitlist_enabled");
      if (response.data.waitlist_enabled) {
        navigate("/waitlist"); // Replace with the path to your waiting list page
      } else {
        if (
          window.location.pathname !== "/sign_up" &&
          window.location.pathname !== "/confirm-subscription-single"
        ) {
          navigate("/sign_up", { replace: true });
        }
      }
    } catch (error) {
      console.error("Error checking waiting list status:", error);
      // Handle the error as appropriate
    }
  };

  useEffect(() => {
    // Directly managing side-effects here, no conditionals around hook calls.
    if (isLoading || !user) return;

    const fetchData = async () => {
      if (localStorage.getItem("tempToken")) {
        // If we still have a tempToken in local storage, then the user registration
        // is not complete. -- Until then, we cannot make an /api/user call, so
        // hold off for now.
        console.log("AuthRoleCheck waiting for user registration to complete.");
        return;
      }

      const storedState = JSON.parse(localStorage.getItem(STORAGE_KEY));
      if (!triggerRefresh && storedState && !storedState.notFound) {
        setState(storedState);
      } else {
        try {
          const response = await AuthenticatedApi.get("/api/user");
          if (response.data.role === "not found") {
            navigate("/sign_up", { replace: true });
            return;
          }
          const newState = {
            isAdmin: response.data.role.includes("admin"),
            notFound: response.data.role === "not found",
            loaded: true,
            role: response.data.role,
            email: response.data.email,
            name: response.data.name,
            userId: response.data.user_id,
            enabledTasks: response.data.enabled_tasks,
            faxNumbers: response.data.fax_numbers,
            lastUpdatedAt: response.data.last_updated_at,
            isSuperAdmin: response.data.is_super_admin,
            isGroupAdmin: response.data.is_group_admin,
            orgId: response.data.org_id,
            orgName: response.data.org_name,
            switchedOrg:
              response.data.original_org_id != null &&
              response.data.org_id !== response.data.original_org_id,
          };
          setState(newState);
          localStorage.setItem(STORAGE_KEY, JSON.stringify(newState));
        } catch (error) {
          console.error("Error checking auth:", error);
          console.error("Error checking auth:", error);
          if (!error.response) {
            if (error.message && error.message === "Network Error") {
              navigate("/service_down");
            }
          } else {
            console.error("HTTP error: ", error.response.data);
            if (error.response.status === 500) {
              navigate("/service_down");
            } else {
              const newState = {
                ...state,
                notFound: true,
              };
              setState(newState);
              checkWaitListStatus();
            }
          }
        }
        setTriggerRefresh(false);
      }
    };

    fetchData();
  }, [isLoading, user, navigate, triggerRefresh]);

  return { ...state, setTriggerRefresh };
}

export default useAuthRoleCheck;
