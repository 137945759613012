import { Pagination, Row } from "antd";
import TaskCard from "./TaskCard";

const PaginatedCards = ({
  tasks,
  pageSize,
  total,
  currentPage,
  selectedData,
  onPageChange,
  onPageSizeChange,
  handleRowClick,
  handleCheckboxChange,
  handleSave,
  isSingleUser,
  role,
  refreshResubmittedTask,
  fetchTaskTitle,
  deleteTask,
}) => {
  return (
    <div style={{ marginTop: "28px" }}>
      <Row justify="center">
        {tasks.map((task) => (
          <TaskCard
            task={task}
            key={task.id}
            onClick={(e) => handleRowClick(e, task)}
            onChange={(e) => handleCheckboxChange(e, task)}
            selectedData={selectedData}
            handleSave={handleSave}
            isSingleUser={isSingleUser}
            role={role}
            refreshResubmittedTask={refreshResubmittedTask}
            fetchTaskTitle={fetchTaskTitle}
            deleteTask={deleteTask}
          />
        ))}
      </Row>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={total}
        onChange={onPageChange}
        onShowSizeChange={onPageSizeChange}
        showLessItems
        showSizeChanger
      />
    </div>
  );
};

export default PaginatedCards;
