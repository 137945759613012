import { useEffect, useState } from "react";
import { Table, notification } from "antd";
import "./UsagePage.css";
import "../style.css";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import { useAuth0 } from "@auth0/auth0-react";
import { handleError } from "../utils/utilities";
import LoadingComponent from "./LoadingComponent";

function UsagePage() {
  const [data, setData] = useState([]);
  const [monthOffset, setMonthOffset] = useState(0);

  const [isAdmin, setIsAdmin] = useState(null);
  var { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0();
  const [notFound, setNotFound] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleNextMonth = () => {
    setMonthOffset(monthOffset + 1);
  };

  const handlePreviousMonth = () => {
    // if (monthOffset > 0) {
    setMonthOffset(monthOffset - 1);
    // }
  };

  const getMonthAndYear = (offset) => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + offset);

    const monthName = currentDate.toLocaleString("default", { month: "long" });
    const year = currentDate.getFullYear();

    return `${monthName} ${year}`;
  };

  const fetchData = () => {
    console.log(user.sub); // Logs the user's unique Auth0 ID
    console.log(user.email);
    AuthenticatedApi.get(`/api/usage?month_offset=${monthOffset}`)
      .then((response) => {
        console.log(response.data);
        if (response.data.role === "not found") {
          setNotFound(true);
        } else if (response.data["data"] === "not admin") {
          setIsAdmin(false);
        } else {
          setData(response.data.data);
          setIsAdmin(response.data.role.includes("admin"));
        }
      })
      .catch((error) => {
        console.log(error);
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
      })
      .finally(() => {
        setChecked(true);
      });
  };

  useEffect(() => {
    // Authentication check
    const checkAuth = async () => {
      if (!isLoading && !isAuthenticated) {
        await loginWithRedirect({ appState: { returnTo: "/" } });
      } else if (!isLoading && isAuthenticated && !checked) {
        fetchData();
      }
    };
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, loginWithRedirect, checked]);

  useEffect(() => {
    // Fetch data when monthOffset changes
    if (isAuthenticated && !isLoading) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, monthOffset]);

  const columns = [
    {
      title: "Service",
      dataIndex: "service",
    },
    {
      title: "#",
      dataIndex: "value",
    },
  ];

  return (
    <div>
      {!checked && <LoadingComponent />}
      {notFound && checked && (
        <div className="loading">
          The email you entered was not found in our database. Please contact
          elad@synthpop.ai to set up an account.
        </div>
      )}
      {!isAdmin && checked && (
        <div className="loading">
          You do not have permission to access this page.
        </div>
      )}

      {!isLoading && !notFound && checked && isAdmin && (
        <div className="usage-container">
          <div
            className="month-navigation"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <button onClick={handlePreviousMonth}>Previous Month</button>
            <span
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
              }}
            >
              Usage for Month: {getMonthAndYear(monthOffset)}
            </span>
            <button onClick={handleNextMonth}>Next Month</button>
          </div>
          <br />
          <Table dataSource={data} columns={columns} className="usage-table" />
        </div>
      )}
    </div>
  );
}

export default UsagePage;
