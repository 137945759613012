import { useState, useContext } from "react";
import { Switch, notification, Form, Typography, Button } from "antd";
import { AuthenticatedApi } from "../../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../../utils/utilities";
import ConfigContext from "../../context/ConfigContext";

const { Title } = Typography;
const ConfigureFieldExtractor = (props) => {
  const [form] = Form.useForm();
  const appConfig = useContext(ConfigContext);
  const { config, setConfig } = props;
  const [waitingConfig, setWaitingConfig] = useState(false);

  const handleSwitchChange = (field) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      [field]: !prevConfig[field],
    }));
  };

  const onExtractorFinish = async () => {
    setWaitingConfig(true);
    console.log("Received values of form:", config);
    let formData = new FormData();
    formData.append("field_extraction_config", JSON.stringify(config));
    try {
      await AuthenticatedApi.post(
        "/api/update-field-extraction-config",
        formData
      );
      form.resetFields();
      notification.success({
        message: formatMessage(
          appConfig.messages.tasks.extraction_config_updated
        ),
      });
    } catch (error) {
      console.error(error);
      const errorMessage = handleError(error);
      notification.error({ message: errorMessage });
    } finally {
      setWaitingConfig(false);
    }
  };

  return (
    <div className="edit-profile-container">
      <Title className="edit-profile-title">Configure Field Extractor</Title>
      {config && (
        <Form
          name="field_extractor_form"
          onFinish={onExtractorFinish}
          autoComplete="off"
        >
          {Object.keys(config).map((key) => (
            <Form.Item key={key} name={key} valuePropName="checked">
              <Switch
                checkedChildren="Enabled"
                unCheckedChildren="Disabled"
                checked={config[key]}
                onChange={() => handleSwitchChange(key)}
                disabled={key === "demographics"}
              />{" "}
              {key
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </Form.Item>
          ))}

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={waitingConfig}>
              Save Configuration
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default ConfigureFieldExtractor;
