import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingComponent from "./LoadingComponent";

const LoginRedirect = () => {
  const { orgName } = useParams();
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, [orgName, loginWithRedirect]);

  return <LoadingComponent />;
};

export default LoginRedirect;
