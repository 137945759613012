import { forwardRef, useImperativeHandle } from "react";
import { Form, Input, Row, Col } from "antd";
import { WarningOutlined } from "@ant-design/icons";

const PatientInfoForm = forwardRef(({ patientInfoDetail }, ref) => {
  const {
    trackingInfo,
    stepsStatus,
    validationForm,
    handleReSubmit,
    non_insurance_task_type,
    setIsSubmitDisabled,
    setUpdatedValidatedForm,
  } = patientInfoDetail;

  useImperativeHandle(ref, () => ({
    handleReSubmit: () => {
      return handleReSubmit(validationForm.getFieldsValue());
    },
  }));

  if (!trackingInfo || stepsStatus.status !== "Blocked") {
    return null;
  }

  const handleInputChange = () => {
    setIsSubmitDisabled(false);
    setUpdatedValidatedForm(true);
  };

  return (
    <Form
      form={validationForm}
      initialValues={trackingInfo}
      style={{ marginTop: "1%" }}
    >
      <div
        style={{
          paddingTop: "5px",
        }}
      >
        {/* Dynamic input fields */}
        {renderInputField(
          "First Name",
          "first_name",
          stepsStatus.id,
          handleInputChange,
          trackingInfo
        )}
        {renderInputField(
          "Last Name",
          "last_name",
          stepsStatus.id,
          handleInputChange,
          trackingInfo
        )}
        {renderInputField(
          "DOB",
          "DOB",
          stepsStatus.id,
          handleInputChange,
          trackingInfo
        )}
        {stepsStatus.task_type !== non_insurance_task_type &&
          renderInputField(
            "Insurance Carrier",
            "insurance_carrier",
            stepsStatus.id,
            handleInputChange,
            trackingInfo
          )}
        {stepsStatus.task_type !== non_insurance_task_type &&
          trackingInfo.insurance_id &&
          renderInputField(
            "Insurance Id",
            "insurance_id",
            stepsStatus.id,
            handleInputChange,
            trackingInfo,
            !trackingInfo.validMedicareNumber
          )}
      </div>
    </Form>
  );
});

// Assign a display name to your component
PatientInfoForm.displayName = "PatientInfoForm";

const renderInputField = (
  label,
  name,
  id,
  handleInputChange,
  trackingInfo,
  warningCondition = false
) => (
  <div key={`${name}-${id}`} style={{ marginBottom: "10px" }}>
    <Row gutter={8} align="middle">
      <Col xs={24} sm={4} style={{ textAlign: "left" }}>
        <span>{label}:</span>
      </Col>
      <Col xs={20} sm={14}>
        <Form.Item name={name} noStyle>
          <Input
            placeholder={
              !trackingInfo[name]
                ? `Add patient ${label.toLowerCase()} here`
                : ""
            }
            onChange={handleInputChange}
          />
        </Form.Item>
      </Col>
      <Col>
        {(!trackingInfo[name] || warningCondition) && (
          <WarningOutlined style={{ color: "red", fontSize: "18px" }} />
        )}
      </Col>
    </Row>
  </div>
);

export default PatientInfoForm;
