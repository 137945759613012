import { useContext, useState } from "react";
import {
  Tooltip,
  Select,
  Input,
  notification,
  Form,
  Typography,
  Button,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { AuthenticatedApi } from "../../utils/AuthenticatedApi";
import { handleError, formatMessage } from "../../utils/utilities";
import ConfigContext from "../../context/ConfigContext";

const { Title } = Typography;
const { Option } = Select;
const AddUser = (props) => {
  const appConfig = useContext(ConfigContext);
  const { setData } = props;
  const [waiting, setWaiting] = useState(false);

  const addUser = (values) => {
    setWaiting(true);
    AuthenticatedApi.post("/api/add-user", {
      name: values.name,
      email: values.email,
      role: values.role,
    })
      .then((response) => {
        console.log(response);
        setWaiting(false);
        setData((prevData) => [...prevData, response.data]);
        notification.success({
          message: formatMessage(appConfig.messages.user.added),
        });
      })
      .catch((error) => {
        setWaiting(false);
        const errorMessage = handleError(error);
        notification.error({ message: errorMessage });
      });
  };

  return (
    <div className="edit-profile-container">
      <Title className="edit-profile-title">Add User</Title>
      <Form layout={"vertical"} name="addUser" onFinish={addUser}>
        <Form.Item
          className="edit-profile-text"
          label={
            <span>
              Name&nbsp;
              <Tooltip title="Enter the full name of the user.">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          name="name"
          rules={[
            {
              required: true,
              message: "Please input the name of the user",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="edit-profile-text"
          label={
            <span>
              Email&nbsp;
              <Tooltip title="Enter the user's email address. It should be an organization email.">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          name="email"
          rules={[
            {
              required: true,
              message: "Please input the email of the user",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="edit-profile-text"
          label={
            <span>
              Role&nbsp;
              <Tooltip
                title={
                  <span>
                    Select a role: <br />
                    <strong>Admin</strong> - has full access to all settings.{" "}
                    <br />
                    <strong>Member</strong> - has limited access.
                  </span>
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
          name="role"
          rules={[{ required: true }]}
        >
          <Select>
            <Option value="admin">Admin</Option>
            <Option value="member">Member</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={waiting}>
            Add User
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddUser;
