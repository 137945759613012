import { redirect } from "react-router-dom";
import "../style.css";

const LoginPage = () => {
  const handleLogout = () => {
    redirect(window.location.origin);
    console.log("here");
  };

  handleLogout();

  return null; // This component does not render anything
};

export default LoginPage;
