import logo from "./../assets/Symbol.png";

const LoadingComponent = () => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      width: "100vw",
    },
    logo: {
      width: "200px",
      animation: "rotateLogo 2s linear infinite",
    },
  };

  return (
    <div style={styles.container}>
      <img src={logo} alt="Logo" style={styles.logo} />
    </div>
  );
};

export default LoadingComponent;
