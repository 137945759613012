import { useEffect } from "react";
import { message } from "antd";
import { AuthenticatedApi } from "../utils/AuthenticatedApi";
import "./EditProfilePage.css";
import "../style.css";
import { useAuthRole } from "../context/AuthRoleContext";
import LoadingComponent from "./LoadingComponent";

const ConfirmSubscription = () => {
  const { email, name } = useAuthRole();
  console.log(localStorage.getItem("token"));

  useEffect(() => {
    const checkAuth = async () => {
      if (name && email) {
        AuthenticatedApi.post(
          "/api/stripe-subscribe",
          {
            name: name,
            email: email,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        ).then((response) => {
          if (response.status === 200) {
            window.location.href = "/account";
          } else {
            message.error(response.data.message);
          }
        });
      }
    };

    checkAuth();
  }, [name, email]);

  return <LoadingComponent />;
};

export default ConfirmSubscription;
