import {
  Create,
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
} from "react-admin";

export const ListCoverage = (props) => {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="location" />
        <TextField source="description" />
      </Datagrid>
    </List>
  );
};

export const CreateCoverage = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="location" />
        <TextInput source="description" />
      </SimpleForm>
    </Create>
  );
};

export const EditCoverage = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <TextInput source="location" />
        <TextInput source="description" />
      </SimpleForm>
    </Edit>
  );
};
