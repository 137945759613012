import { useState, useRef } from "react";
import { Card, Button, Tag, Dropdown } from "antd";
import {
  EditOutlined,
  ExclamationCircleOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  MoreOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import TimeAgo from "react-timeago";
import EditableCell from "./EditableCell";
import ReRunModal from "./ReRunModal";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import "./TaskCard.css";
import { useTheme } from "./../context/ThemeContext";

const TaskCard = ({
  task,
  id,
  onClick,
  onChange,
  selectedData,
  handleSave,
  role,
  refreshResubmittedTask,
  fetchTaskTitle,
  deleteTask,
}) => {
  const { isDarkMode } = useTheme();
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [keepExistingType, setKeepExistingType] = useState(false);

  const [editing, setEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(task.name);
  const failedStatuses = ["Failed", "Blocked", "Invalid"];

  const handleNameChange = (e) => {
    setEditedValue(e.target.value);
  };

  const handleCardClick = (e) => {
    if (dropdownRef.current && dropdownRef.current.contains(e.target)) {
      return;
    }
    onClick(e);
  };

  const handleModalClose = () => {
    setModalVisible(false); // Close the modal
  };

  const handleConfirmReRun = (taskId) => {
    console.log(`Re-running task ${taskId}`);
    setModalVisible(false);
    // Insert your re-run task logic or function call here
  };

  const handleMenuClick = (event) => {
    if (event.key === "rerun" && role === "superadmin") {
      setModalVisible(true);
    } else if (event.key === "rerun-with-existing" && role === "superadmin") {
      setModalVisible(true);
      setKeepExistingType(true); // Set this state to true for re-run with existing
    } else if (event.key === "delete" && role === "superadmin") {
      setDeleteModalVisible(true);
    } else {
      const isClearAction = event.key === "close";
      const syntheticEvent = {
        stopPropagation: () => {},
        target: { checked: isClearAction },
      };
      onChange(syntheticEvent, task);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const pendingFormatter = (value, unit, suffix, date, defaultFormatter) => {
    // For pending tasks instead of the default rendering of 'X minutes ago', we want
    // to render 'for X minutes`

    // TODO: once we support more languages, this will be too simplistic for plurals.
    if (value !== 1) {
      unit += "s";
    }
    return `for ${value} ${unit}`;
  };

  const menuItems = [
    task.cleared
      ? { key: "reopen", label: "Reopen" }
      : {
          key: "close",
          label: "Close",
          // TODO: Enable this later if we want to restrict closing tasks to only certain statuses
          // disabled: !["Completed", "Failed", "Invalid", "Blocked"].includes(task.status),
        },
    role === "superadmin" && { key: "rerun", label: "Re-run New" },
    role === "superadmin" && {
      key: "rerun-with-existing",
      label: "Re-run Existing",
    },
    role === "superadmin" && { key: "delete", label: "Delete" },
  ].filter(Boolean); // To remove any false/null items

  const menu = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  const cancel = (event) => {
    event.stopPropagation();
    setEditing(false);
  };

  const toggleEdit = (e) => {
    e.stopPropagation();
    setEditing(!editing);
  };

  const save = (e) => {
    e.stopPropagation();
    handleSave(e, task.id, "name", editedValue);
    setEditing(false);
  };

  const saveTitle = (e, key, dataIndex, value) => {
    handleSave(e, key, dataIndex, value);
    setEditing(false);
  };

  const handleDeleteConfirm = () => {
    deleteTask(task.id);
    setDeleteModalVisible(false);
  };

  const handleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  const renderStatus = (
    status,
    // eslint-disable-next-line no-unused-vars
    createdOn = "",
    completedOn = "",
    pendingStart = ""
  ) => {
    const statusMapping = {
      Pending: {
        formatter: pendingFormatter,
        date: pendingStart,
        color: undefined,
        marker: undefined,
      },
      Processing: {
        formatter: pendingFormatter,
        date: pendingStart,
        color: undefined,
        marker: undefined,
      },
      Blocked: {
        formatter: undefined,
        date: completedOn,
        color: "orange",
        marker: <ExclamationCircleOutlined />,
      },
      Failed: {
        formatter: undefined,
        date: completedOn,
        color: "red",
        marker: undefined,
      },
      Invalid: {
        formatter: undefined,
        date: completedOn,
        color: "red",
        marker: undefined,
      },
      Completed: {
        formatter: undefined,
        date: completedOn,
        color: "green",
        marker: undefined,
      },
    };

    const attrs = statusMapping[status];

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span className="mr-8" style={{ color: attrs.color }}>
          {status}
        </span>
        <span className="mr-8">
          <TimeAgo date={attrs.date} formatter={attrs.formatter} /> by{" "}
          {task.creator}
        </span>
        <span>
          <Tag color="#2db7f5">{fetchTaskTitle(task.task_type)}</Tag>
        </span>
        {attrs.marker}
      </div>
    );
  };

  function formatDateWithTime(dateString) {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      // timeZoneName: 'short'
    };
    return date.toLocaleTimeString("en-US", options);
  }

  const cardTitle = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          maxWidth: "85%",
          overflowX: "hidden",
        }}
      >
        {editing ? (
          <EditableCell
            ref={inputRef}
            editing={editing}
            dataIndex="name"
            title="Name"
            inputType="text"
            record={task}
            handleSave={saveTitle}
            handleNameChange={handleNameChange}
          />
        ) : (
          <span>{task.name}</span> // Click to start editing
        )}
      </div>
      {task.status === "Completed" && task.completed_on && (
        <span className="mr-8" style={{ fontSize: "10px" }}>
          {formatDateWithTime(task.completed_on)}
        </span>
      )}
      {failedStatuses.includes(task.status) && task.created_on && (
        <span className="mr-8" style={{ fontSize: "10px" }}>
          <ExclamationCircleOutlined style={{ color: "orange" }} />{" "}
          {formatDateWithTime(task.created_on)}
        </span>
      )}
      {editing ? (
        <span>
          <Button
            className="task-card-btn"
            icon={<SaveOutlined />}
            onClick={save}
            title="Save changes."
          />
          <Button
            className="task-card-btn"
            icon={<CloseCircleOutlined />}
            onClick={cancel}
            title="Cancel changes."
          />
        </span>
      ) : (
        <span>
          {!task.cleared &&
            !(task.status === "Pending" || task.status === "Processing") && (
              <Button
                className="task-card-btn"
                icon={<EditOutlined />}
                onClick={toggleEdit}
                title="Edit task name."
              />
            )}
          {task.cleared && <CheckOutlined />}
        </span>
      )}
      <Dropdown menu={menu} trigger={["click"]}>
        <Button
          ref={dropdownRef}
          className="task-card-btn"
          icon={<MoreOutlined />}
          title="More options."
        />
      </Dropdown>
    </div>
  );

  const cardBackgroundStyle = {
    backgroundColor: isDarkMode ? "#222" : "#e3e3e3",
    width: 300,
    margin: 3,
  };

  const selectedBackgroundStyle = {
    backgroundColor: isDarkMode ? "#444" : "#f0f0f0",
    width: 300,
    margin: 3,
  };

  return (
    <Card
      key={id}
      className={
        selectedData &&
        selectedData.selectedRow &&
        task.id === selectedData.selectedRow.id
          ? "selected-task-card"
          : "task-card"
      }
      title={cardTitle}
      style={
        selectedData && task.id === selectedData.selectedRow.id
          ? selectedBackgroundStyle
          : cardBackgroundStyle
      }
      onClick={handleCardClick}
    >
      <div>
        {renderStatus(
          task.status,
          task.created_on,
          task.completed_on,
          task.pending_start
        )}
      </div>
      <ReRunModal
        visible={modalVisible}
        onClose={handleModalClose}
        onConfirm={handleConfirmReRun}
        task={task}
        refreshResubmittedTask={refreshResubmittedTask}
        keepExistingType={keepExistingType}
      />
      <ConfirmDeleteModal
        visible={isDeleteModalVisible}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      />
    </Card>
  );
};

export default TaskCard;
